import React, { useState, useEffect } from 'react';
import './Project.css';
import axios from 'axios';
import { getStoredData } from "../../json/fetchData.js";
// import AllIntroTemplate from '../../components/AllIntroTemplate/AllIntroTemplate';
import ProjectLocation from '../../components/ProjectLocation/ProjectLocation';
import AllProjectsList from '../../components/AllProjectsList/AllProjectsList';
// import PhotoSlider from '../../components/CompanyJourney/CompanyTimelineGrid';
import ProjectTimeline from '../../components/ProjectTimeline/ProjectTimeline.js';
import LoadingIcon from '../../components/Loading/Loading';

const data = getStoredData()?.["4"] ?? {};

const Project = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch project data from the API
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get('https://avenue-website-backend.vercel.app/projects');
        setProjects(response.data);  // Set the fetched data into the projects state
        setLoading(false);
      } catch (error) {
        console.error('Error fetching project data:', error);
        setError('Failed to load projects. Please try again later.');
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <LoadingIcon /> {/* Use LoadingIcon while loading */}
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div> 
      {/* Introduction Template */}
      {/* <AllIntroTemplate 
        title={data.ProjectIntroTitle} 
        sentence={data.ProjectIntroSentence} 
        photo={data.ProjectIntroPhoto}
      /> */}
<ProjectTimeline cards={data.Timeline}/>
      {/* Project Location Map */}
      <ProjectLocation />

      {/* Pass the fetched projects to AllProjectsList */}
      <AllProjectsList projects={projects} />
    </div>
  );
}

export default Project;
