// import React from 'react';
// import './ProjectAbout.css';

// const ProjectAbout = ({ logo, about, address, rera, photos, quote }) => {

//   const formatTextWithLineBreaks = (text) => {
//     return text.split('. ').map((sentence, index) => (
//       <span key={index}>
//         {sentence}.
//         <br />
//         <br className="line-break" />
//       </span>
//     ));
//   };

//   // Encode the paths to handle spaces and special characters
//   const encodedPhotos = encodeURI(photos);

//   return (
//     <div className="project-about-container">
//       <div className="project-about-left">
//         {/* Conditionally render the logo section */}
//         {logo && (
//           <img src={encodeURI(logo)} alt="Project Logo" className="project-logo" />
//         )}
//         <p className="project-address"> {address}</p>
//         <p className="project-quote"> {quote}</p>
//         <p className="project-about-text"> {formatTextWithLineBreaks(about)}</p>
//         <p className="project-rera">RERA No: {rera}</p>
//       </div>
//       <div className="project-about-right">
//         <img src={encodedPhotos} alt="Project Photos" className="project-photo" />
//       </div>
//     </div>
//   );
// };

// export default ProjectAbout;


import React from 'react';
import './ProjectAbout.css';

const ProjectAbout = ({ logo, about, address, rera, quote }) => {
  const formatTextWithLineBreaks = (text) => {
    return text.split('. ').map((sentence, index) => (
      <span key={index}>
        {sentence}.
        <br />
        <br className="line-break" />
      </span>
    ));
  };

  return (
    <div className="project-about-container">
      <div className="project-about-left">
        {logo && (
          <img src={encodeURI(logo)} alt="Project Logo" className="project-logo" />
        )}
        <p className="project-address">{address}</p>
        <p className="project-quote">{quote}</p>
        <p className="project-about-text">{formatTextWithLineBreaks(about)}</p>
        <p className="project-rera">RERA No: {rera}</p>
      </div>
    </div>
  );
};

export default ProjectAbout;
