import React, { useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams to get the project ID from the URL
import axios from 'axios'; // Import axios for API requests
import TestimonialCard from '../../components/HomeTestimonials/TestimonialCard'; // Import the TestimonialCard component
import './ProjectTestimonial.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ProjectTestimonial = () => {
  const { id } = useParams(); // Get the project ID from the route parameters
  const sliderRef = useRef(null);
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch all testimonials and filter them by project ID
    const fetchData = async () => {
      try {
        const response = await axios.get('https://avenue-website-backend.vercel.app/testimonial'); // Fetch all testimonials
        // Filter testimonials by project ID (make sure to compare strings)
        const filteredTestimonials = response.data.filter(testimonial => testimonial.project.toString() === id); 
        setTestimonialsData(filteredTestimonials); // Set the filtered testimonials
        setLoading(false);
      } catch (error) {
        console.error('Error fetching testimonials:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]); // Re-run the effect when the project ID changes

  useEffect(() => {
    const interval = setInterval(() => {
      if (sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  // Adjust slider settings based on the number of testimonials
  const settings = {
    dots: true,
    infinite: testimonialsData.length > 1, // Disable infinite scrolling if there's only one testimonial
    speed: 1000,
    slidesToShow: Math.min(testimonialsData.length, 1), // Show only the number of testimonials available
    slidesToScroll: 1,
    autoplay: testimonialsData.length > 1, // Disable autoplay if there's only one testimonial
    autoplaySpeed: 10000,
  };

  if (loading) {
    return <div>Loading testimonials...</div>;
  }

  // Return null if no testimonials are found
  if (testimonialsData.length === 0) {
    return null;
  }

  return (
    <div className='testimonial-container'>
      <h1 className="testimonial-title">What Our Clients Say</h1>
      <p className="testimonial-para">Here's what our clients have to say about this project.</p>
      <div className="testimonial-carousel-wrapper">
        <div className="testimonial-carousel-container">
          <Slider ref={sliderRef} {...settings}>
            {testimonialsData.map((testimonial, index) => (
              <div key={index}>
                <TestimonialCard testimonial={testimonial} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default ProjectTestimonial;
