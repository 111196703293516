import React, { useState, useEffect } from 'react';
import './ProjectOngoing.css';
import axios from 'axios';
import AllIntroTemplate from '../../components/AllIntroTemplate/AllIntroTemplate';
import ProjectLocation from '../../components/ProjectLocation/ProjectLocation';
import AllProjectsList from '../../components/AllProjectsList/AllProjectsList';
import { getStoredData } from "../../json/fetchData.js";
import LoadingIcon from '../../components/Loading/Loading';

const data = getStoredData()?.["4"] ?? {}

const ProjectOngoing = () => {
  const [ongoingProjects, setOngoingProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch project data from the API and filter for ongoing projects
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get('https://avenue-website-backend.vercel.app/projects');
        const filteredOngoingProjects = response.data.filter(project => project.status === 'Ongoing');
        setOngoingProjects(filteredOngoingProjects);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching project data:', error);
        setError('Failed to load ongoing projects. Please try again later.');
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <LoadingIcon /> {/* Use LoadingIcon while loading */}
      </div>
    );
  }
 

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div> 
      <AllIntroTemplate 
        title={data.OngoingProjectIntroTitle} 
        sentence={data.OngoingProjectIntroSentence} 
        photo={data.OngoingProjectIntroPhoto}
      />
      <ProjectLocation />
      {/* Pass the filtered ongoing projects to AllProjectsList */}
      <AllProjectsList projects={ongoingProjects} />
    </div>
  );
}

export default ProjectOngoing;
