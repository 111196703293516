import React, { useRef, useEffect, useState } from 'react';
import TestimonialCard from './TestimonialCard'; // Import the TestimonialCard component
import './HomeTestimonials.css'; // Import the CSS file
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'
import axios from 'axios'; // Import axios
import { getStoredData } from "../../json/fetchData.js";

const HomeTestimonials = () => {
  const sliderRef = useRef(null);
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const data = getStoredData();
const totalData = data ? data["2"] : {};

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get('https://avenue-website-backend.vercel.app/testimonial'); // Replace with your API URL
        setTestimonialsData(response.data); // Set the fetched data
        setLoading(false);
      } catch (error) {
        console.error('Error fetching testimonials:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
  };

  if (loading) {
    return <div>Loading testimonials...</div>;
  }

  return (
    <div className='testimonial-container'>
      <h1 className="testimonial-title">{totalData.HomeTestimonialTitle}</h1>
      <p className="testimonial-para">{totalData.HomeTestimonialPara}</p>
      <div className="testimonial-carousel-wrapper">
        <div className="testimonial-carousel-container">
          <Slider ref={sliderRef} {...settings}>
            {testimonialsData.map((testimonial, index) => (
              <div key={index}>
                <TestimonialCard testimonial={testimonial} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default HomeTestimonials;
