import React, { useState, useEffect } from 'react';
import './HomeAboutAvenue.css';
// import { Link } from 'react-router-dom';
import CustomLink from '../../components/CustomLink/CustomLink';

const HomeAboutAvenue = ({ title, sentence, word, link, linktext, video, videotext }) => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [displayedWord, setDisplayedWord] = useState('');
  const [isTyping, setIsTyping] = useState(true);

  // Typewriter effect to cycle through the words
  useEffect(() => {
    const wordInterval = setInterval(() => {
      setIsTyping(true);
      setDisplayedWord('');
      const wordArray = word[currentWordIndex].split('');
      wordArray.forEach((char, index) => {
        setTimeout(() => {
          setDisplayedWord((prev) => prev + char);
          if (index === wordArray.length - 1) {
            setIsTyping(false);
          }
        }, index * 150); // Adjust typing speed
      });

      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % word.length);
    }, 4000); // Time interval for each word change

    return () => clearInterval(wordInterval);
  }, [currentWordIndex, word]);

  return (
    <div className='HomeAboutAvenueContainer'>
      <div className='HomeAboutAvenueVideoSection'>
        <iframe
          className='HomeAboutAvenueVideo'
          src={video}
          title="What's In The Work?"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        {/* <p className='HomeAboutAvenueVideoText'>{videotext}</p> */}
      </div>

      <div className='HomeAboutAvenueTextSection'>
        <h1 className='HomeAboutAvenueTitle'>{title}</h1>
        <p className='HomeAboutAvenueSentence'>
          {sentence}
          <span className={`HomeAboutAvenueWord ${isTyping ? 'typing' : ''}`}>{displayedWord}</span>
        </p>
        {/* <Link to={link} className='HomeAboutAvenueLink'>
          {linktext}
        </Link> */}
        <CustomLink link={link} linktext={linktext} />
      </div>
    </div>
  );
};

export default HomeAboutAvenue;
