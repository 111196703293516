import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom'; // Import useParams from react-router-dom
import './ProjectPage.css';
import { getStoredData } from "../../json/fetchData.js";
import ProjectIntroVideo2 from '../../components/ProjectIntroVideo/ProjectIntroVideo2';
import ProjectAbout from '../../components/ProjectAbout/ProjectAbout';
import ProjectImageScroll2 from '../../components/ProjectImageScroll/ProjectImageScroll2';
import ProjectTestimonial from '../../components/ProjectTestimonial/ProjectTestimonial';
import ProjectVideoOnline from '../../components/ProjectVideoOnline/ProjectVideoOnline';
import ProjectFloorPlan from '../../components/ProjectFloorPlan/ProjectFloorPlan';
import ProjectAmenities from '../../components/ProjectAmenities/ProjectAmenities';
import ProjectLocationHighlight from '../../components/ProjectLocationHighlight/ProjectLocationHighlight';
import ProjectMapLocation from '../../components/ProjectMapLocation/ProjectMapLocation';
import HomeContactUs from '../../components/HomeContactUs/HomeContactUs';
import HomeProjects from '../../components/HomeProjects/HomeProjects';
import FloatingForm from '../../components/FloatingForm/FloatingForm'; // Import FloatingForm
import LoadingIcon from '../../components/Loading/Loading.js';

const ProjectPage = () => {
  const { id } = useParams(); // Get the id from the URL params
  const [projectData, setProjectData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const totalData = getStoredData()?.["2"] ?? {}
  const image = getStoredData()?.["11"] ?? {}

  useEffect(() => {
    // Fetch project data based on the ID from the route params
    axios.get(`https://avenue-website-backend.vercel.app/projects/${id}`)
      .then(response => {
        setProjectData(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
  }, [id]); // Dependency array includes id, so it will refetch data if the id changes

  if (loading) return <div><LoadingIcon/></div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <ProjectIntroVideo2 data={projectData.introPhotos} logo={projectData.logo}/>
      <ProjectAbout logo={projectData.logo} about={projectData.about} address={projectData.location.address} rera={projectData.reraNumber} photos={projectData.photos[0].url} quote={projectData.quote}/>
      <ProjectAmenities name={projectData.name} amenities={projectData.aminities}/>
      <ProjectImageScroll2 data={projectData.photos} title={image.Heading} detail={image.Details}/>
      <ProjectImageScroll2 data={projectData.actualphotos} title={image.Heading2} detail={image.Details2}/>
      
      <ProjectVideoOnline video={projectData.videourl} title={projectData.videotext} />
      <ProjectFloorPlan data={projectData.floorplan} brochureUrl={projectData.brochure} site={projectData.name}/>
      <ProjectLocationHighlight data={projectData.keyplaces} sentence={projectData.location[0].sentence}/>
      <ProjectMapLocation location={projectData.location}  projectName={projectData.name} marker={projectData.logo} qr={projectData.reraQrcode} rera={projectData.reraNumber}/>
      <ProjectTestimonial/>
      <HomeProjects />
      <HomeContactUs photo={projectData.photos[0].url} text={totalData.HomeContactUsText} button={totalData.HomeContactUsButton} link={totalData.HomeContactUsLink} />
      
      {/* Call the floating form component */}
      <FloatingForm />
    </div>  
  )
}

export default ProjectPage;
