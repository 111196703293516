import React from 'react';
import "./ProjectVideoOnline.css";

const ProjectVideoOnline = ({ video, title }) => {

  if (!video || video.toLowerCase() === "na") {
    return null;
  }

  const getEmbedUrl = (url) => {
    let videoId;

    // Check for various YouTube URL formats
    const urlPatterns = [
      /youtube\.com\/embed\/([^"&?/ ]{11})/,    // Format: https://www.youtube.com/embed/VIDEO_ID
      /youtube\.com\/watch\?v=([^"&?/ ]{11})/,   // Format: https://www.youtube.com/watch?v=VIDEO_ID
      /youtu\.be\/([^"&?/ ]{11})/                // Format: https://youtu.be/VIDEO_ID
    ];

    for (const pattern of urlPatterns) {
      const match = url.match(pattern);
      if (match) {
        videoId = match[1];
        break;
      }
    }

    return videoId ? `https://www.youtube.com/embed/${videoId}` : null;
  };

  const embedUrl = video ? getEmbedUrl(video) : null;

  return (
    <div className="events-video-wrapper">
        <h2 className="events-video-subtitle">{title}</h2>
        <div className="events-video-container">
          {embedUrl ? (
            <iframe
              src={embedUrl}
              title="Speaking Video"
              className="events-video-frame"
              allowFullScreen
            ></iframe>
          ) : (
            <video
              className="events-video-frame"
              controls
            >
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
    </div>
  );
}

export default ProjectVideoOnline;
