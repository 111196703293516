import React from 'react';
import './Loading.css';

const LoadingIcon = () => {
  return (
    <div className="loading-icon">
      <div className="loading-circle"></div>
      <img src="/logo.png" alt="Company Logo" className="loading-logo" /> {/* Use public folder path */}
    </div>
  );
};

export default LoadingIcon;
