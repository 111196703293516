import React from 'react';
import './LoadingWave.css'; // Ensure you include the CSS file

const LoadingWave = () => {
  return (
    <div className="loading-wave">
      <div className="loading-bar"></div>
      <div className="loading-bar"></div>
      <div className="loading-bar"></div>
      <div className="loading-bar"></div>
      <div className="loading-bar"></div>
    </div>
  );
};

export default LoadingWave;
