import React from 'react';
import './Home.css';
import HomeIntro from '../../components/HomeIntro/HomeIntro';
import HomeAboutAvenue from '../../components/HomeAboutAvenue/HomeAboutAvenue';
import HomeProjects from '../../components/HomeProjects/HomeProjects';
import HomeTestimonials from '../../components/HomeTestimonials/HomeTestimonials';
import HomeBlog from '../../components/HomeBlog/HomeBlog';
import HomeContactUs from '../../components/HomeContactUs/HomeContactUs';
import { getStoredData } from "../../json/fetchData.js";



const Home = () => {

const data = getStoredData();
const totalData = data ? data["2"] : {};
  return (
    <div>
      <HomeIntro company={totalData.HomeIntroCompany} values={totalData.HomeIntroValues} link={totalData.HomeIntroLink} linktext={totalData.HomeIntroLinkText} sentence={totalData.HomeIntrosentence} photo={totalData.HomeIntroPhoto}/>
      <HomeAboutAvenue title={totalData.HomeAboutTitle} sentence={totalData.HomeAboutSentence} word={totalData.HomeAboutWordChange} link={totalData.HomeAboutLink} linktext={totalData.HomeAboutLinkText} video={totalData.HomeAboutVideo} videotext={totalData.HomeAboutVideoText}/>
      <HomeProjects />
      <HomeTestimonials/>
      <HomeBlog/>
      <HomeContactUs  photo={totalData.HomeContactUsPhoto} text={totalData.HomeContactUsText} button={totalData.HomeContactUsButton} link={totalData.HomeContactUsLink}/>
    </div>
  )
}

export default Home;
