import React, { useState, useEffect } from 'react';
import './ProjectFloorPlan.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import Button2 from '../Button/Button2';
import axios from 'axios';

const ProjectFloorPlan = ({ data, brochureUrl ,site }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);
  const [transformOrigin, setTransformOrigin] = useState('center center');
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [formData, setFormData] = useState({ name: '', phone: '', email: '' });
  const [isRegistered, setIsRegistered] = useState(false);

  // Check if the user token exists and is valid
  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (token) {
      const { expiry } = JSON.parse(token);
      if (new Date() < new Date(expiry)) {
        setIsRegistered(true);
      } else {
        localStorage.removeItem('userToken');
      }
    }
  }, []);

  // Return null if floor plan data is missing or empty
  if (!data || data.length === 0) {
    return null;
  }

  const openModal = (index) => {
    setCurrentIndex(index);
    if (isRegistered) {
      setIsOpen(true);
    } else {
      openFormModal();
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsZoomed(false);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    setIsZoomed(false);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? data.length - 1 : prevIndex - 1
    );
    setIsZoomed(false);
  };

  const toggleZoom = () => {
    if (isRegistered) {
      setIsZoomed(!isZoomed);
    } else {
      openFormModal();
    }
  };


  

  const handleMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    setTransformOrigin(`${x}% ${y}%`);
  };

  const openFormModal = () => {
    setIsFormOpen(true);
  };

  const closeFormModal = () => {
    setIsFormOpen(false);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
  
    if (name === 'phone') {
      // Allow only numeric values and ensure the length does not exceed 10 digits
      const sanitizedValue = value.replace(/\D/g, ''); // Remove non-numeric characters
      if (sanitizedValue.length <= 10) {
        setFormData({ ...formData, [name]: sanitizedValue });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  

  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    if (formData.name && formData.phone && formData.email) {
      try {
        // Include the site field in the form data
        const submissionData = { ...formData, site };
  
        await axios.post('https://avenue-website-backend.vercel.app/form', submissionData);
  
        // Store a token in localStorage with a 3-day expiration
        const token = {
          expiry: new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000), // 3 days from now
        };
        localStorage.setItem('userToken', JSON.stringify(token));
        setIsRegistered(true);
        closeFormModal();
        handleDownload();
      } catch (error) {
        console.error('Error submitting the form:', error);
        alert('There was an error submitting your details. Please try again.');
      }
    } else {
      alert('Please fill in all the details');
    }
  };
  

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = brochureUrl;
    link.setAttribute('download', 'Brochure.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="project-floorplan-container">
      <h2 className="project-floorplan-title">Floor Plans</h2>
      <div className="floorplan-grid">
        {data.slice(0, 2).map((floor, index) => (
          <img
            key={index}
            src={floor.photo}
            alt={`Floor ${index + 1}`}
            className="floorplan-image"
            onClick={() => openModal(index)}
          />
        ))}
      </div>
  
      {/* Render "Download Brochure" button if the user is registered */}
      {isRegistered && (
        <Button2 onClick={handleDownload} >
          Download Brochure
        </Button2>
      )}
  
      {isOpen && isRegistered && (
        <div className="modal">
          <div className="modal-content">
            <FontAwesomeIcon icon={faTimes} className="close-icon" onClick={closeModal} />
            <img
              src={data[currentIndex].photo}
              alt={`Floor ${currentIndex + 1}`}
              className={`modal-image ${isZoomed ? 'zoomed' : ''}`}
              onClick={toggleZoom}
              onMouseMove={handleMouseMove}
              style={{ transformOrigin }}
            />
            <div className="modal-navigation">
              <FontAwesomeIcon icon={faChevronLeft} className="nav-icon" onClick={handlePrev} />
              <FontAwesomeIcon icon={faChevronRight} className="nav-icon" onClick={handleNext} />
            </div>
          </div>
        </div>
      )}
  
      {isFormOpen && (
        <div className="modal">
          <div className="modal-content form-modal">
            <FontAwesomeIcon icon={faTimes} className="close-icon" onClick={closeFormModal} />
            <h3>Enter your details to download the brochure</h3>
            <form onSubmit={handleFormSubmit} className="download-form">
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleFormChange}
              />
              <input
                type="text"
                name="phone"
                placeholder="Phone Number"
                value={formData.phone}
                onChange={handleFormChange}
                pattern="\d{10}" // Ensures 10 digits are entered
                title="Please enter a valid 10-digit phone number"
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleFormChange}
              />
              <Button2 type="submit">Submit & Download</Button2>
            </form>
          </div>
        </div>
      )}
    </div>
  );
  
};

export default ProjectFloorPlan;
