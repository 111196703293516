import React from 'react';
import './ProjectLocationHighlight.css';

const ProjectLocationHighlight = ({ data, sentence }) => {
  // Check if sentence is defined, otherwise provide a default empty string
  const formattedSentence = sentence ? sentence.split('.').filter(part => part.trim() !== '') : [];

  return (
    <div className="location-highlight-container">
      <h2 className="location-highlight-title">Location Highlights</h2>

      {/* Render the sentence as a list */}
      {formattedSentence.length > 0 && (
        <ul className="location-highlight-points">
          {formattedSentence.map((part, index) => (
            <li key={index}>
              {part.trim() + '.'}
            </li>
          ))}
        </ul>
      )}

      {/* Show a message if there are no locations available */}
      {data.length === 0 ? (
        <p className="no-locations-message">No locations available at the moment.</p>
      ) : (
        <div className="location-highlight-grid">
          {data.map((place) => (
            <div key={place._id} className="location-card">
              <img src={place.icon} alt={place.name} className="location-icon" />
              <div className="location-info">
                <h3 className="location-name">{place.name}</h3>
                <p className="location-distance">{place.distance}</p>
                {place.url && (
                  <a href={place.url} target="_blank" rel="noopener noreferrer" className="location-url">
                    Open in Maps
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProjectLocationHighlight;
