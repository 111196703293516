import React from 'react';
import './Career.css';
import AllIntroTemplate from '../../components/AllIntroTemplate/AllIntroTemplate';
import CareerHiring from '../../components/CareerHiring/CareerHiring';
import { getStoredData } from "../../json/fetchData.js";

const Career = () => {
  const data = getStoredData()?.["15"] ?? {};
  return (
    <div>
         <AllIntroTemplate title={data.CareerIntroTitle} sentence={data.CareerIntroSentence} photo={data.CareerIntroPhoto}/>
         <CareerHiring cards={data.CareerHiring}/>
    </div>
  )
}

export default Career