import React from 'react';
import './SliderBlog.css';
import Button from '../Button/Button';
import { Link as RouterLink } from 'react-router-dom';

const Link = ({ to, children, ...rest }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <RouterLink to={to} onClick={handleClick} {...rest}>
      {children}
    </RouterLink>
  );
};

const SliderBlog = ({ data }) => {
  if (!data || data.length === 0) {
    return <p>No blogs available.</p>;
  }

  return (
    <div className='NewsRoomList-Container'>
      <div className='NewsRoomList-AllCards'>
        {data.map((newsItem) => (
          <Link key={newsItem.id} to={`/blog/${newsItem._id}`} className='NewsRoomList-CardLink'>
            <div className='NewsRoomList-Card'>
              <div className='NewsRoomList-CardLeft'>
                <img src={newsItem.photo} alt={newsItem.title} className='NewsRoomList-image' />
              </div>
              <div className='NewsRoomList-CardRight'>
                <p className='NewsRoomList-Date'>{newsItem.date}</p>
                <h1 className='NewsRoomList-Title'>{newsItem.title}</h1>
                <p className='NewsRoomList-Description'>{newsItem.details}</p>
                <div className='NewsRoomList-ButtonContainer'>
                  <Button to={`/news/${newsItem.id}`}>CONTINUE READING</Button>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SliderBlog;
