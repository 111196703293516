import React from 'react';
import './Services.css';
import { getStoredData } from "../../json/fetchData.js";
import AllIntroTemplate from '../../components/AllIntroTemplate/AllIntroTemplate';
import AllAboutUs from '../../components/AllAboutUs/AllAboutUs';
import ServicesTitle from '../../components/ServicesTitle/ServicesTitle';
import ServicesList from '../../components/ServicesList/ServicesList';
import ContactUsForm from '../../components/ContactUsForm/ContactUsForm';

const data = getStoredData()?.["8"] ?? {};

const Services = () => {
  return (
    <div>
      <AllIntroTemplate title={data.ServicesIntroTitle} sentence={data.ServicesIntroSentence} photo={data.ServicesIntroPhoto}/>
      <AllAboutUs title={data.AboutUsTitle} sentence={data.AboutUsSentence} card={data.AboutUsCard}/>
      <ServicesTitle title={data.ServicesTitle} header={data.ServicesHeader} sentence={data.ServicesSentence} number={data.ServicesNumber}/>
      <ServicesList data={data.ServicesCards}/>
      <ContactUsForm data ={data.ServicesContent}/>
    </div>
  )
}

export default Services;
