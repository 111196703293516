import React from 'react';
import './AllAboutUs.css';

const AllAboutUs = ({ title, sentence, card }) => {
  return (
    <div className="about-us-container">
      <div className="about-us-info">
        <h2 className='about-us-title'>{title}</h2>
        <p className='about-us-sentence'>{sentence}</p>
      </div>
      <div className="about-us-cards">
        {card.map((item, index) => (
          <div className="about-us-card" key={index}>
            <h3 className='about-us-cardtitle'>{item.title}</h3>
            <h4 className='about-us-cardheading'>{item.heading}</h4>
            <p className='about-us-cardsentence'>{item.sentence}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllAboutUs;
