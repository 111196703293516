import React from 'react';
import './AllValues.css';
import { FaStar, FaUsers, FaShieldAlt, FaHandshake, FaLeaf, FaClipboardCheck } from 'react-icons/fa'; // Import some icons
// import totalData from "../../json/data.json"; // Assuming the path is correct
import { getStoredData } from "../../json/fetchData.js";

const AllValues = () => {
  const totalData = getStoredData();
  const { values } = totalData[14]; // Destructure the values array from your data
  
  // Map of icons corresponding to each value
  const icons = {
    "Excellence": <FaStar />,
    "Teamwork": <FaUsers />,
    "Integrity": <FaShieldAlt />,
    "Commitment": <FaHandshake />,
    "Sustainability": <FaLeaf />,
    "Accountability": <FaClipboardCheck />
  };

  return (
    <div className="all-values-container">
      {values.map((value, index) => (
        <div className="value-card" key={index}>
          <div className="value-icon">
            {icons[value.title]} {/* Display the icon based on title */}
          </div>
          <h3 className="value-title">{value.title}</h3>
          <p className="value-sentence">{value.sentence}</p>
        </div>
      ))}
    </div>
  );
};

export default AllValues;
