import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ProductList from '../../components/DynamicList&Grid/DynamicListGrid';
import { getStoredData } from "../../json/fetchData.js";
import { useNavigate } from 'react-router-dom';
import "./AdminProjects.css";
import LoadingIcon from '../../components/Loading/Loading'; // Import the LoadingIcon component

const AdminProjects = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const fields = getStoredData()?.["19"]?.AdminProjects ?? {};
  const totalData = getStoredData()?.["19"] ?? {};

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://avenue-website-backend.vercel.app/projects');
        setProducts(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <LoadingIcon /> {/* Use LoadingIcon while loading */}
      </div>
    );
  }

  return (
    <div className="admin-products">
      <h2>{totalData.AdminProductListTitle}</h2>
      <button onClick={() => navigate(`${totalData.AdminProductListLink}`)}>
        {totalData.AdminProductListButton}
      </button>
      <ProductList
        products={products}
        fields={fields}
        redirect={totalData.AdminProjectsEdit}
        deleteApi="https://avenue-website-backend.vercel.app/projects"
      />
    </div>
  );
};

export default AdminProjects;
