import React from 'react';
import { getStoredData } from "../../json/fetchData.js";
import DynamicForm from '../../components/DynamicForm/DynamicForm';

const AddBlogPage = () => {
  const fields = getStoredData()?.["19"]?.AdminBlog ?? {};
  const apiUrl = 'https://avenue-website-backend.vercel.app/blog'; // API endpoint for creating product
  const successRedirect = '/AdminBlog'; // Redirect after successful product creation

  return (
    <div>
      <DynamicForm fields={fields} apiUrl={apiUrl} successRedirect={successRedirect} />
    </div>
  );
};
export default AddBlogPage