import React from 'react';
import { getStoredData } from "../../json/fetchData.js";
import DynamicForm from '../../components/DynamicForm/DynamicForm';

const AddServicesPage = () => {
  const fields = getStoredData()?.["19"]?.AdminServices ?? {}
  const apiUrl = 'https://avenue-website-backend.vercel.app/services'; // API endpoint for creating product
  const successRedirect = '/AdminServices'; // Redirect after successful product creation

  return (
    <div>
      <DynamicForm fields={fields} apiUrl={apiUrl} successRedirect={successRedirect} />
    </div>
  );
};
export default AddServicesPage