import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faCalendarAlt, faTimes } from '@fortawesome/free-solid-svg-icons'; // Import faTimes for the close icon
import axios from 'axios';
import './FloatingForm.css';

const FloatingForm = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [formType, setFormType] = useState('');
  const [formData, setFormData] = useState({ name: '', email: '', phone: '', site: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [projects, setProjects] = useState([]); // State to store fetched project data

  useEffect(() => {
    // Fetch project data for the dropdown
    const fetchProjects = async () => {
      try {
        const response = await axios.get('https://avenue-website-backend.vercel.app/projects');
        setProjects(response.data); // Store fetched projects in the state
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };
    fetchProjects();
  }, []);

  const toggleForm = () => {
    if (isFormOpen) {
      setFormType(''); // Reset the form type when closing the form
    }
    setIsFormOpen(!isFormOpen); // Toggle form visibility
  };

  const handleFormTypeChange = (type) => {
    setFormType(type);
  };

  const handleContactRedirect = () => {
    window.location.href = 'tel:+918530236969'; // Redirect to the phone number
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post('https://avenue-website-backend.vercel.app/form', formData);
      console.log(response.data);
      setLoading(false);
      alert('Form submitted successfully!');
      setFormData({ name: '', email: '', phone: '', site: '' });
      setIsFormOpen(false);
    } catch (error) {
      console.error('Error submitting form:', error);
      setError('There was an error submitting the form. Please try again.');
      setLoading(false);
    }
  };

  return (
    <>
      {/* Sticky Floating Button */}
      <div className="floating-button" onClick={toggleForm}>
        <FontAwesomeIcon icon={isFormOpen ? faTimes : faCalendarAlt} /> {/* Change icon based on form state */}
      </div>

      {/* Form Pop-up */}
      {isFormOpen && (
        <div className="floating-form">
          {/* Conditionally render the buttons */}
          {!formType && (
            <>
              <h3>What would you like to do?</h3>
              <button onClick={() => handleFormTypeChange('visit')}>
                <FontAwesomeIcon icon={faCalendarAlt} /> Schedule a Visit
              </button>
              <button onClick={handleContactRedirect}>
                <FontAwesomeIcon icon={faEnvelope} /> Contact Us
              </button>
            </>
          )}

          {/* Show Visit Form */}
          {formType === 'visit' && (
            <form className="visit-form" onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              <input
                type="text"
                name="phone"
                placeholder="Phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
              <select
                name="site"
                value={formData.site}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Site Interested In</option>
                {projects.map((project) => (
                  <option key={project._id} value={project.name}>
                    {project.name}
                  </option>
                ))}
              </select>
              <button type="submit" disabled={loading}>
                {loading ? 'Submitting...' : 'Submit'}
              </button>
              {error && <p className="error-message">{error}</p>}
            </form>
          )}
        </div>
      )}
    </>
  );
};

export default FloatingForm;
