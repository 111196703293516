import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import NavBar from './pages/NavBar/NavBar';
import Footer from './pages/Footer/Footer';
import Home from './pages/Home/Home';
import Corporate from './pages/Corporate/Corporate';
import Project from './pages/Project/Project';
import MediaNews from './pages/Media&News/Media&News';
import Contact from './pages/Contact/Contact';
import AwardsRecognition from './pages/Awards&Recognition/Awards&Recognition';
import NRIService from './pages/NRIService/NRIService';
import Services from './pages/Services/Services';
import ProjectPage from './pages/ProjectPage/ProjectPage'; 
import MediaNewsPage from './pages/MediaNewsPage/MediaNewsPage';
import BlogPage from './pages/BlogPage/BlogPage'; 
import Blog from './pages/Blog/Blog';
import Events from './pages/Events/Events.js';
import Career from './pages/Career/Career';
import NotFound from './components/NotFound/NotFound';
import ProjectCommercial from './pages/ProjectCommercial/ProjectCommercial';
import ProjectOngoing from './pages/ProjectOngoing/ProjectOngoing';
import ProjectResidential from './pages/ProjectResidential/ProjectResidential';
import ProjectUpcoming from './pages/ProjectUpcoming/ProjectUpcoming.js';
import ProjectCoworking from './pages/ProjectCoworking/ProjectCoworking.js';
import Admin from './pages/Admin/Admin';
import AdminProjects from './pages/AdminProjects/AdminProjects';
import AdminBlog from './pages/AdminBlog/AdminBlog';
import AdminServices from './pages/AdminServices/AdminServices';
import AdminHiring from './pages/AdminHiring/AdminHiring';
import AdminTestimonial from './pages/AdminTestimonial/AdminTestimonial';
import AdminSubmission from './pages/AdminSubmission/AdminSubmission.js';
import AddProjectsPage from './pages/AddProjectsPage/AddProjectsPage';
import AddServicesPage from './pages/AddServicesPage/AddServicesPage';
import AddBlogPage from './pages/AddBlogPage/AddBlogPage';
import AddHiringPage from './pages/AddHiringPage/AddHiringPage';
import AddTestimonialsPage from './pages/AddTestimonialsPage/AddTestimonialsPage';
import EditProjectsPage from './pages/EditProjectsPage/EditProjectsPage';
import EditServicesPage from './pages/EditServicesPage/EditServicesPage';
import EditBlogPage from './pages/EditBlogPage/EditBlogPage';
import EditHiringPage from './pages/EditHiringPage/EditHiringPage';
import EditTestimonialPage from './pages/EditTestimonialPage/EditTestimonialPage';
import EditSubmissionPage from './pages/EditSubmissionPage/EditSubmissionPage';
import Login from './pages/Login/Login.js';
import PrivateRoute from './pages/PrivateRoute/PrivateRoute.js';
import PopupAd from './components/PopupAd/PopupAd.js';
import useScrollToTop from './components/useScrollToTop/useScrollToTop.js';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy.js';
import CookieConsent from './components/CookieConsent/CookieConsent.js';



const AppRoutes = () => {
  return (
    <Router>
      <div>
        <RoutesWithLayout />
      </div>
    </Router>
  );
};

const PublicLayout = ({ children }) => (
  <>
    <NavBar />
    {children}
    <Footer />
    <CookieConsent /> 
  </>
);

const PrivateLayout = ({ children }) => (
  <>
    {children}
  </>
);

const RoutesWithLayout = () => {
  const location = useLocation();
  useScrollToTop();

  return (
    <>
      {location.pathname === '/' && <PopupAd />}
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<PublicLayout><Home /></PublicLayout>} />
        <Route path="/home" element={<PublicLayout><Home /></PublicLayout>} />
        <Route path="/profile" element={<PublicLayout><Corporate /></PublicLayout>} />
        <Route path="/about-us" element={<PublicLayout><Corporate /></PublicLayout>} />
        <Route path="/projects" element={<PublicLayout><Project /></PublicLayout>} />
        <Route path="/residential" element={<PublicLayout><ProjectResidential /></PublicLayout>} />
        <Route path="/residential-projects" element={<PublicLayout><ProjectResidential /></PublicLayout>} />
        <Route path="/commercial-projects" element={<PublicLayout><ProjectResidential /></PublicLayout>} />
        <Route path="/commercial" element={<PublicLayout><ProjectCommercial /></PublicLayout>} />
        <Route path="/ongoing" element={<PublicLayout><ProjectOngoing /></PublicLayout>} />
        <Route path="/upcoming" element={<PublicLayout><ProjectUpcoming /></PublicLayout>} />
        <Route path="/coworking" element={<PublicLayout><ProjectCoworking /></PublicLayout>} />
        <Route path="/coming-soon" element={<PublicLayout><ProjectOngoing /></PublicLayout>} />
        <Route path="/medianews" element={<PublicLayout><MediaNews /></PublicLayout>} />
        <Route path="/contact" element={<PublicLayout><Contact /></PublicLayout>} />
        <Route path="/awardsrecognition" element={<PublicLayout><AwardsRecognition /></PublicLayout>} />
        <Route path="/nri" element={<PublicLayout><NRIService /></PublicLayout>} />
        <Route path="/nri-services" element={<PublicLayout><NRIService /></PublicLayout>} />
        <Route path="/services" element={<PublicLayout><Services /></PublicLayout>} />
        <Route path="/project/:id" element={<PublicLayout><ProjectPage /></PublicLayout>} />
        <Route path="/medianews/:id" element={<PublicLayout><MediaNewsPage /></PublicLayout>} />
        <Route path="/career" element={<PublicLayout><Career /></PublicLayout>} />
        <Route path="/blog" element={<PublicLayout><Blog /></PublicLayout>} />
        <Route path="/events" element={<PublicLayout><Events /></PublicLayout>} />
        <Route path="/privacy-policy" element={<PublicLayout><PrivacyPolicy /></PublicLayout>} />
        <Route path="/blog/:id" element={<PublicLayout><BlogPage /></PublicLayout>} />

        {/* Private Routes */}
        <Route path="/admin" element={<PrivateRoute><PrivateLayout><Admin /></PrivateLayout></PrivateRoute>} />
        <Route path="/AdminProjects" element={<PrivateRoute><PrivateLayout><AdminProjects /></PrivateLayout></PrivateRoute>} />
        <Route path="/AdminServices" element={<PrivateRoute><PrivateLayout><AdminServices /></PrivateLayout></PrivateRoute>} />
        <Route path="/AdminBlog" element={<PrivateRoute><PrivateLayout><AdminBlog /></PrivateLayout></PrivateRoute>} />
        <Route path="/AdminHiring" element={<PrivateRoute><PrivateLayout><AdminHiring /></PrivateLayout></PrivateRoute>} />
        <Route path="/AdminTestimonial" element={<PrivateRoute><PrivateLayout><AdminTestimonial /></PrivateLayout></PrivateRoute>} />
        <Route path="/AdminSubmission" element={<PrivateRoute><PrivateLayout><AdminSubmission /></PrivateLayout></PrivateRoute>} />
        <Route path="/AddProjectsPage" element={<PrivateRoute><PrivateLayout><AddProjectsPage /></PrivateLayout></PrivateRoute>} />
        <Route path="/AddServicesPage" element={<PrivateRoute><PrivateLayout><AddServicesPage /></PrivateLayout></PrivateRoute>} />
        <Route path="/AddBlogPage" element={<PrivateRoute><PrivateLayout><AddBlogPage /></PrivateLayout></PrivateRoute>} />
        <Route path="/AddHiringPage" element={<PrivateRoute><PrivateLayout><AddHiringPage /></PrivateLayout></PrivateRoute>} />
        <Route path="/AddTestimonialsPage" element={<PrivateRoute><PrivateLayout><AddTestimonialsPage /></PrivateLayout></PrivateRoute>} />
        <Route path="/AdminProjects/:id" element={<PrivateRoute><PrivateLayout><EditProjectsPage /></PrivateLayout></PrivateRoute>} />
        <Route path="/AdminServices/:id" element={<PrivateRoute><PrivateLayout><EditServicesPage /></PrivateLayout></PrivateRoute>} />
        <Route path="/AdminHiring/:id" element={<PrivateRoute><PrivateLayout><EditHiringPage /></PrivateLayout></PrivateRoute>} />
        <Route path="/AdminTestimonial/:id" element={<PrivateRoute><PrivateLayout><EditTestimonialPage /></PrivateLayout></PrivateRoute>} />
        <Route path="/AdminBlog/:id" element={<PrivateRoute><PrivateLayout><EditBlogPage /></PrivateLayout></PrivateRoute>} />
        <Route path="/AdminSubmission/:id" element={<PrivateRoute><PrivateLayout><EditSubmissionPage /></PrivateLayout></PrivateRoute>} />

        {/* Other Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
