import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"; // Example icon
import './Button.css'; // Assuming you have a separate CSS file for styles
import { Link as RouterLink } from 'react-router-dom';

const Link = ({ to, children, ...rest }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <RouterLink to={to} onClick={handleClick} {...rest}>
      {children}
    </RouterLink>
  );
};

const Button = ({ children, to, icon, type, ...rest }) => {
  const iconToUse = icon || faArrowRight;

  // If "to" is provided, render a <Link> for navigation
  if (to) {
    return (
      <Link to={to} className="btn" {...rest}>
        {children}
        <FontAwesomeIcon icon={iconToUse} className="icon-HomeIntro" />
      </Link>
    );
  }

  // Otherwise, render a <button> for form submission or other actions
  return (
    <button type={type || "button"} className="btn" {...rest}>
      {children}
      <FontAwesomeIcon icon={iconToUse} className="icon-HomeIntro" />
    </button>
  );
};

export default Button;
