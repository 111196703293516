import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ProductList from '../../components/DynamicList&Grid/DynamicListGrid';
import { getStoredData } from "../../json/fetchData.js";
import "./AdminSubmission.css";
import LoadingIcon from '../../components/Loading/Loading';

const AdminSubmission = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const fields = getStoredData()?.["19"]?.AdminSubmission ?? {};
  const totalData = getStoredData()?.["19"] ?? {};

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://avenue-website-backend.vercel.app/form');
        setProducts(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const downloadFile = async (format) => {
    try {
      const url = `https://avenue-website-backend.vercel.app/form/export/${format}`;
      const response = await axios.get(url, {
        responseType: 'blob', // Important for downloading files
      });

      // Create a blob from the response and download it
      const blob = new Blob([response.data], { type: format === 'excel' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'text/csv' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `submissions.${format === 'excel' ? 'xlsx' : 'csv'}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(`Error downloading ${format} file:`, error);
      alert(`Failed to download ${format.toUpperCase()} file.`);
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <LoadingIcon /> {/* Use LoadingIcon while loading */}
      </div>
    );
  }

  return (
    <div className="admin-products">
      <h2>Form List</h2>
      <div className="download-buttons">
        <button onClick={() => downloadFile('excel')} className="download-button">
          Download Excel
        </button>
        <button onClick={() => downloadFile('csv')} className="download-button">
          Download CSV
        </button>
      </div>
      <ProductList
        products={products}
        fields={fields}
        redirect={totalData.AdminSubmissionEdit}
        deleteApi="https://avenue-website-backend.vercel.app/form"
      />
    </div>
  );
};

export default AdminSubmission;
