import React from 'react';
import { Link } from 'react-router-dom'; // Correct import from react-router-dom
import { FaArrowRight } from 'react-icons/fa'; // Import FontAwesome icon
import './CustomLink2.css'; // Import corresponding CSS

const CustomLink2 = ({ link, linktext }) => {
  return (
    <Link to={link} className='CustomLink2'>
      {linktext} <FaArrowRight className='CustomLink2Icon' /> {/* Add icon in front of the link text */}
    </Link>
  );
};

export default CustomLink2;
