import React from 'react';
import './AwardsList.css';

const AwardsList = () => {
  return (
    <div>AwardsList</div>
  )
}

export default AwardsList;
