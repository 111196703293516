import React, { useState, useEffect } from 'react';
import './HomeIntro.css';
import CustomLink from '../../components/CustomLink/CustomLink';

const HomeIntro = ({ company, values, link, linktext, sentence, photo }) => {
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

  // Function to cycle through background images every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPhotoIndex((prevIndex) => (prevIndex + 1) % photo.length);
    }, 5000);

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [photo.length]);

  const getImageSource = (photo) => {
    const path = photo.startsWith('http') ? photo : `${process.env.PUBLIC_URL}${photo}`;
    return encodeURI(path); // Encode to handle spaces and special characters
  };

  return (
    <div
      className='HomeIntroContainer'
      style={{
        backgroundImage: `url(${getImageSource(photo[currentPhotoIndex])})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        transition: 'background-image 1s ease-in-out, transform 5s ease', // Add zoom transition
      }}
    >
      <div className='HomeIntroContent'>
        <div className='HomeIntroLeft'>
          <h1 className='HomeIntroCompany'>{company}</h1>
          <ul className='HomeIntroValues'>
            {values.map((value, index) => (
              <li key={index}>{value}</li>
            ))}
          </ul>
          {/* <Link to={link} className='HomeIntroLink'>
            {linktext} <FaArrowRight className='HomeIntroLinkIcon'/>
          </Link> */}
          <CustomLink link={link} linktext={linktext} />
        </div>

        <div className='HomeIntroRight'>
          <p className='HomeIntroSentence'>{sentence}</p>
        </div>
      </div>
    </div>
  );
};

export default HomeIntro;
