import React from 'react';
import { getStoredData } from "../../json/fetchData.js";
import DynamicForm from '../../components/DynamicForm/DynamicForm';

const AddProjectsPage = () => {
  const fields = getStoredData()?.["19"]?.AdminProjects ?? {};
  const apiUrl = 'https://avenue-website-backend.vercel.app/projects'; // API endpoint for creating product
  const successRedirect = '/AdminProjects'; // Redirect after successful product creation

  return (
    <div>
      <DynamicForm fields={fields} apiUrl={apiUrl} successRedirect={successRedirect} />
    </div>
  );
};

export default AddProjectsPage;