import React, { useState, useEffect, useRef } from 'react';
import './HomeProjects.css'; // Your CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import CustomLink2 from '../CustomLink/CustomLink2.js';
import { getStoredData } from "../../json/fetchData.js";
import LoadingIcon from '../../components/Loading/Loading';


const HomeProjects = () => {
  const [projects, setProjects] = useState([]); // State to store project data
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cardWidth, setCardWidth] = useState(200); // Default width
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const carouselRef = useRef(null);

const data = getStoredData();
const totalData = data ? data["2"] : {};

  // Fetch projects data from the API
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch('https://avenue-website-backend.vercel.app/projects');
        if (!response.ok) {
          throw new Error('Error fetching projects data');
        }
        const data = await response.json();
        // Sort projects by priority
        const sortedData = data.sort((a, b) => a.priority - b.priority);
        setProjects(sortedData); // Set sorted data to projects state
        setLoading(false); // Stop loading
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchProjects(); // Call the function to fetch data on component mount
  }, []);

  useEffect(() => {
    const updateCardWidth = () => {
      if (carouselRef.current) {
        const containerWidth = carouselRef.current.offsetWidth;
        const isMobile = window.innerWidth <= 786;
        const cardsToShow = isMobile ? 1 : 3; // Adjust for responsive layout
        const cardWidth = containerWidth / cardsToShow;
        setCardWidth(cardWidth);
        setCurrentIndex(prev => Math.min(prev, projects.length - cardsToShow));
      }
    };
  
    window.addEventListener('resize', updateCardWidth);
    updateCardWidth(); // Initial calculation
  
    return () => {
      window.removeEventListener('resize', updateCardWidth);
    };
  }, [projects]);
  

  const totalCards = projects.length;
  const visibleCards = window.innerWidth <= 786 ? 1 : 7; // Adjust based on screen width

  const handleNextClick = () => {
    setCurrentIndex(prev => {
      const maxIndex = Math.max(0, totalCards - visibleCards); // Ensure we don't go beyond the card count
      return prev < maxIndex ? prev + 1 : 0;
    });
  };
  

  const handlePrevClick = () => {
    setCurrentIndex(prev => {
      const maxIndex = Math.ceil(totalCards / visibleCards) - 1;
      return prev > 0 ? prev - 1 : maxIndex; // Loop back to the last page if at the start
    });
  };

  const handleMouseEnter = (e) => {
    const video = e.target;
    if (video.readyState >= 3) { // Ensure video is ready to play
      video.play();
    }
  };

  const handleMouseLeave = (e) => {
    const video = e.target;
    setTimeout(() => {
      video.pause();
    }, 150); // Add a slight delay before pausing to prevent flickering
  };

  const getImageSource = (photo) => {
    const path = photo.startsWith('http') ? photo : `${process.env.PUBLIC_URL}${photo}`;
    return encodeURI(path); // Encode to handle spaces and special characters
  };



  if (loading) {
    return (
      <div className="loading-container">
        <LoadingIcon /> {/* Use LoadingIcon while loading */}
      </div>
    );
  }
 

  if (error) {
    return <p>{error}</p>;
  }

  // title={totalData.HomeProjectsTitle} linktext={totalData.HomeProjectsLinkText}

  return (
    <div className="carousel-container">
      <div className="carousel-header">
        <h1 className="carousel-title">"{totalData.HomeProjectsTitle}"</h1>
        <CustomLink2 link={totalData.HomeProjectsLink} linktext={totalData.HomeProjectsLinkText} />
        <div className="carousel-buttons">
          <button className="carousel-button" onClick={handlePrevClick}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button className="carousel-button" onClick={handleNextClick}>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>

      <div className="carousel-wrapper" ref={carouselRef}>
        <div
          className="carousel"
          style={{ transform: `translateX(-${currentIndex * cardWidth}px)` }}
        >
          {projects.map((project) => (
            <Link key={project._id} to={`/${totalData.HomeProjectsLink2}/${project._id}`} className="carousel-card">
              <div className="video-container">
                {project.video && project.video !== "na" ? (
                  <video
                    className="background-video"
                    muted
                    loop
                    src={process.env.PUBLIC_URL + project.video}
                    title={project.name}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  />
                ) : (
                  <div className="card-photo-container">
                    <img
                      src={getImageSource(project.photos[0].url)}
                      alt={project.name}
                      className="card-photo"
                    />
                  </div>
                )}
                <div className="overlay">
                  <h3 className="card-title">{project.name}</h3>
                  {/* <p className="card-details">{project.about}</p> */}
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeProjects;
