import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './ProjectMapLocation.css';
import Button from "../../components/Button/Button";

const ProjectMapLocation = ({ location, projectName, marker, qr, rera }) => {
  // Safely access the first location object in the array
  const locationData = location?.[0] || {};

  // Destructure the necessary fields from locationData
  const { latitude, longitude, addressFull, url } = locationData;

  // Check if latitude and longitude are valid
  const isValidLatLng = typeof latitude === 'number' && typeof longitude === 'number';

  // Use fallback SVG if marker is not provided
  const markerIcon = marker || '/Projects/sitedata/map.svg';

  // Custom DivIcon for static size marker
  const customIcon = L.divIcon({
    className: 'custom-marker',
    html: `<img src="${markerIcon}" alt="Marker" style="width: 100%; height: auto;" />`,
    iconSize: [100, 70],
    iconAnchor: [50, 35],
  });

  // On button click, navigate to the provided Google Maps URL
  const handleDirectionsClick = () => {
    window.open(url, '_blank'); // Open in a new tab
  };

  // Do not render the component if location data is invalid
  if (!isValidLatLng && !addressFull && !rera && !qr) {
    return null;
  }

  return (
    <div className="project-map-location">
      {/* Render map only if latitude and longitude are valid */}
      {isValidLatLng && (
        <div className="map-container">
          <MapContainer center={[latitude, longitude]} zoom={15} scrollWheelZoom={false} style={{ height: '400px', width: '100%' }}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={[latitude, longitude]} icon={customIcon}>
              <Popup>
                <div className="popup-content">
                  <h4 className='popup-contentname'>{projectName}</h4>
                  <div className='popup-contentButton'>
                    <Button onClick={handleDirectionsClick}>
                      Directions
                    </Button>
                  </div>
                </div>
              </Popup>
            </Marker>
          </MapContainer>
        </div>
      )}

      {/* Section for Address */}
      <div className="project-details">
        {addressFull && (
          <div className="address-section">
            <h3 className='address-sectiontitleaddress'>Site Address</h3>
            <p className='address-sectionaddress'>{addressFull}</p>
          </div>
        )}

        {/* Conditionally render RERA Section */}
        {rera && (
          <div className='address-sectionRight'>
            <div className="rera-section">
              <h3 className='address-sectiontitleaddress'>RERA No:</h3>
              <p>{rera}</p>
              <p><a href="https://maharera.mahaonline.gov.in" target="_blank" rel="noopener noreferrer">https://maharera.mahaonline.gov.in</a></p>
            </div>
          </div>
        )}

        {/* Conditionally render QR Section */}
        {qr && (
          <div className="qr-section">
            <img src={qr} alt="QR Code" className="qr-code-image" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectMapLocation;
