import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import the carousel styles
import './AllKeyMembers.css'; // Your custom CSS

const AllKeyMembers = ({ title, card }) => {
  return (
    <div className='key-members-containerMain'>
      <h2 className="key-members-title">{title}</h2>
    
      <div className="key-members-container">
        <Carousel
          showThumbs={false}
          showStatus={false}
          showArrows={false} // Hide left and right arrows
          infiniteLoop={true}
          autoPlay={true}
          interval={15000} // Set interval for 15 seconds
          transitionTime={600} // Set transition time to 600ms
        >
          {card.map((member, index) => (
            <div key={index} className="key-member-card">
              <img src={member.photo} alt={member.name} className="key-member-photo" />
              <div className="key-member-details">
              <h3 className="key-member-name">{member.name}</h3>
                <p className="key-member-position">{member.position}</p>
                <p className="key-member-sentence">{member.sentence}</p>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default AllKeyMembers;
