import React, { useState, useEffect } from 'react';
import './ServicesList.css';

const ServicesList = () => {
  const [data, setData] = useState([]);
  const [expanded, setExpanded] = useState([]);

  // Fetch data from the API
  useEffect(() => {
    fetch('https://avenue-website-backend.vercel.app/services')
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setExpanded(Array(data.length).fill(false)); // Set expanded state for each item
      })
      .catch((error) => console.error('Error fetching services:', error));
  }, []);

  const toggleExpand = (index) => {
    setExpanded((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <div className='Services-Container'>
      <div className="services-list">
        {data.map((service, index) => (
          <div key={index} className="service-card">
            <img src={service.photo} alt={service.title} className="service-photo" />
            <h3 className="service-title">{service.title}</h3>
            <p className="service-description">{service.description}</p>
            <button onClick={() => toggleExpand(index)} className="read-more-btn">
              {expanded[index] ? 'Read Less' : 'Read More'}
            </button>
            {expanded[index] && (
              <p className="service-sentence">{service.sentence}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesList;
