import React, { useState, useEffect } from "react";
import "./CookieConsent.css"; // Import updated CSS

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setIsVisible(false);
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "declined");
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="cookie-consent">
      <p>
        We use cookies to enhance your experience. Read our{" "}
        <a href="/privacy-policy">Privacy Policy</a>.
      </p>
      <div className="cookie-buttons">
        <button className="accept-btn" onClick={handleAccept}>Accept</button>
        <button className="decline-btn" onClick={handleDecline}>Decline</button>
      </div>
    </div>
  );
};

export default CookieConsent;
