import React from 'react';
import './Awards&Recognition.css';
// import { getStoredData } from "../../json/fetchData.js";
import AllIntroTemplate from '../../components/AllIntroTemplate/AllIntroTemplate';
import AwardsList from '../../components/AwardsList/AwardsList';

const AwardsRecognition = () => {
  return (
    <div>
      <AllIntroTemplate/>
      <AwardsList/>
    </div>
  )
}

export default AwardsRecognition;
