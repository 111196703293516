import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import './HomeBlog.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faGreaterThan } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import CustomLink from '../../components/CustomLink/CustomLink';
import { getStoredData } from "../../json/fetchData.js";

const HomeBlog = () => {
  const [blogs, setBlogs] = useState([]); // State to store blog data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const data = getStoredData();
  const totalData = data ? data["2"] : {};

  useEffect(() => {
    // Fetch blogs data from API
    const fetchBlogs = async () => {
      try {
        const response = await fetch('https://avenue-website-backend.vercel.app/blog');
        if (!response.ok) {
          throw new Error('Error fetching blog data');
        }
        const data = await response.json();
        setBlogs(data); // Set the fetched blogs data
        setLoading(false); // Stop loading
      } catch (err) {
        setError(err.message); // Set error message if fetching fails
        setLoading(false);
      }
    };

    fetchBlogs(); // Call the fetch function on component mount
  }, []); // Empty dependency array ensures it runs only once

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };

  // Handle loading and error states
  if (loading) {
    return <p>Loading blogs...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="blog-latest-container">
      <div className="blog-latest-header-container">
        <h1 className='blog-latest-Header'>{totalData.HomeBlogTitle}</h1>
        <CustomLink link={totalData.HomeBlogLink} linktext={totalData.HomeBlogText} />
      </div>

      <Slider {...settings}>
        {blogs.map((blog, index) => (
          <Link to={`/${totalData.HomeBlogLink}/${blog._id}`} key={index} className="blog-latest-card-link">
            <div className="blog-latest-card">
              <img src={blog.photo} alt={blog.title} className="blog-latest-photo" />
              <h3 className="blog-latest-title">{blog.title}</h3>
              <p className="blog-latest-details">{blog.details}</p>
            </div>
          </Link>
        ))}
      </Slider>
    </div>
  );
};

export default HomeBlog;
