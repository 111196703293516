import React,{useState, useEffect} from 'react';
import './Media&News.css';
// import TotalData from '../../json/data.json';
import AllIntroTemplate from '../../components/AllIntroTemplate/AllIntroTemplate';
import SliderBlog from '../../components/SliderBlog/SliderBlog';
import { getStoredData } from "../../json/fetchData.js";

// const data = TotalData[5];

const MediaNews = () => {

  const data = getStoredData()?.["5"] ?? {}

  const [newsPosts, setnewsPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch news data from the API
    fetch('https://avenue-website-backend.vercel.app/news')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch news data');
        }
        return response.json();
      })
      .then((data) => {
        setnewsPosts(data); // Set the fetched data to state
        setLoading(false); // Stop loading once data is fetched
      })
      .catch((err) => {
        setError(err.message); // Handle any errors
        setLoading(false);
      });
  }, []); // Empty dependency array ensures this runs once when the component mounts

  if (loading) {
    return <p>Loading...</p>; // Show loading message while fetching
  }

  if (error) {
    return <p>Error: {error}</p>; // Show error message if there is an error
  }

  return (
    <div>
      <AllIntroTemplate title={data.MediaIntroTitle} sentence={data.MediaIntroSentence} photo={data.MediaIntroPhoto}/>
      <SliderBlog data={newsPosts}/>
    </div>
  )
}

export default MediaNews;
