import React, { useEffect, useState } from 'react';
import './HomeContactUs.css';
import Button2 from '../../components/Button/Button2';

const HomeContactUs = ({ photo, text, button, link }) => {
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const getImageSource = (photo) => {
    // Use PUBLIC_URL for local images in public folder
    const path = photo.startsWith('http') ? photo : `${process.env.PUBLIC_URL}${photo}`;
    return encodeURI(path); // Encode to handle spaces and special characters
  };

  return (
    <div
      className="home-contactus-container"
      style={{
        backgroundImage: `url(${getImageSource(photo)})`,
        backgroundPositionY: `${scrollY * 0}px`, // Parallax effect on scroll
      }}
    >
      <div className="home-contactus-content">
        <div className="home-contactus-contentInternal">
          <p className="home-contactus-text">{text}</p>
          <Button2 to={link}>{button}</Button2>
        </div>
      </div>
    </div>
  );
};

export default HomeContactUs;
