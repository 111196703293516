import React from 'react';
import OfficeImages from '../../components/OfficeImages/OfficeImages';
import AllIntroTemplate from '../../components/AllIntroTemplate/AllIntroTemplate';
import AllAboutUs from '../../components/AllAboutUs/AllAboutUs';
import { getStoredData } from "../../json/fetchData.js";

const Events = () => {
    const data = getStoredData()?.["21"] ?? {};
  return (
    <div>
        <AllIntroTemplate  title={data.introTitle} sentence={data.introSentence} photo={data.introPhoto}/>
        <AllAboutUs title={data.AboutUsTitle} sentence={data.AboutUsSentence} card={data.AboutUsCard}/>
        <OfficeImages data={data}/>
    </div>
  )
}

export default Events