import React, { useState, useEffect } from 'react';
import './ProjectUpcoming.css';
import axios from 'axios';
import { getStoredData } from "../../json/fetchData.js";
import AllIntroTemplate from '../../components/AllIntroTemplate/AllIntroTemplate';
import ProjectLocation from '../../components/ProjectLocation/ProjectLocation';
import AllProjectsList from '../../components/AllProjectsList/AllProjectsList';
import LoadingIcon from '../../components/Loading/Loading';

const data = getStoredData()?.["4"] ?? {}

const ProjectUpcoming = () => {
  const [UpcomingProjects, setUpcomingProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch all projects from the API and filter for Upcoming projects
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get('https://avenue-website-backend.vercel.app/projects');
        // const filteredUpcomingProjects = response.data.filter(project => project.type === 'Upcoming');
        const filteredUpcomingProjects = response.data.filter(project => project.status === 'Upcoming');
        setUpcomingProjects(filteredUpcomingProjects);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching project data:', error);
        setError('Failed to load projects. Please try again later.');
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <LoadingIcon /> {/* Use LoadingIcon while loading */}
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div> 
      <AllIntroTemplate 
        title={data.UpcomingProjectIntroTitle} 
        sentence={data.UpcomingProjectIntroSentence} 
        photo={data.UpcomingProjectIntroPhoto}
      />
      <ProjectLocation />
      {/* Pass the filtered Upcoming projects to AllProjectsList */}
      <AllProjectsList projects={UpcomingProjects} />
    </div>
  );
}

export default ProjectUpcoming;
