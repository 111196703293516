import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import './NavBar.css';
import { FaFacebook, FaYoutube, FaInstagram, FaLinkedin, FaChevronDown, FaSearch } from 'react-icons/fa';
// import menuData from '../../json/data.json';
import { getStoredData } from "../../json/fetchData.js";
import MobileNavBar from './MobileNavBar';


const menuData = getStoredData();

const ResponsiveNavBar = () => {
  const [menu, setMenu] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false); // Track scroll across all pages
  const location = useLocation(); // Get the current route

  const navRef = useRef(null);
  
  

  useEffect(() => {
    if (menuData.length > 0) {
      setMenu(menuData[0].menu);
    }

    // Apply scroll effect on all pages
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight * 0.2) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleSubMenuToggle = (index) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    const id = setTimeout(() => {
      setActiveIndex(null);
    }, 2000);
    setTimeoutId(id);
  };

  const handleMouseEnter = (index) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    handleSubMenuToggle(index);
  };

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setActiveIndex(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const isActive = (item) => {
    if (location.pathname === item.link) return true;
    if (item.submenu) {
      return item.submenu.some(subItem => location.pathname === subItem.link);
    }
    return false;
  };



  return (
    <nav
      className={`NavBarContainer ${isScrolled ? 'scrolled' : 'transparent'}`} // Apply scroll effect on all pages
      ref={navRef}
    >
      <Link to="/">
        <img src={menuData[0].logo} alt="Logo" className="LogoImage" />
      </Link>

      <div className="NavContent">
        <ul className="NavBarList">
          {menuData[0].menu.map((item, index) => (
            <li
              key={index}
              className={`NavBarItem ${isActive(item) ? 'active' : ''}`}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              {item.link !== 'na' ? (
                <Link to={item.link} className={`NavBarLink ${isActive(item) ? 'active' : ''}`}>
                  {item.name}
                  {item.submenu && (
                    <FaChevronDown className={`ChevronIcon ${activeIndex === index ? 'rotate-up' : 'rotate-down'}`} />
                  )}
                </Link>
              ) : (
                <span className={`NavBarLink ${isActive(item) ? 'active' : ''}`}>
                  {item.name}
                  {item.submenu && (
                    <FaChevronDown className={`ChevronIcon ${activeIndex === index ? 'rotate-up' : 'rotate-down'}`} />
                  )}
                </span>
              )}
              {item.submenu && (
                <ul className={`SubMenuList ${activeIndex === index ? 'show' : ''}`}>
                  {item.submenu.map((subItem, subIndex) => (
                    <li key={subIndex} className="SubMenuItem">
                      {subItem.link !== 'na' ? (
                        <Link
                          to={subItem.link}
                          className={`SubMenuLink ${location.pathname === subItem.link ? 'active' : ''}`}
                        >
                          {subItem.name}
                        </Link>
                      ) : (
                        <span className={`SubMenuLink ${location.pathname === subItem.link ? 'active' : ''}`}>
                          {subItem.name}
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>

        <div className="NavIcons">
          <a href="/search" className="IconLink">
            <FaSearch />
          </a>
          <a href="https://www.instagram.com/avenuehousing/" target="_blank" rel="noopener noreferrer" className="IconLink">
            <FaInstagram />
          </a>
          <a href="https://www.linkedin.com/company/avenue-group-pune/" target="_blank" rel="noopener noreferrer" className="IconLink">
            <FaLinkedin />
          </a>
          <a href="https://www.youtube.com/@AvenueGroupdigital/featured" target="_blank" rel="noopener noreferrer" className="IconLink">
            <FaYoutube />
          </a>
          <a href="https://www.facebook.com/avenuehousing/" target="_blank" rel="noopener noreferrer" className="IconLink">
            <FaFacebook />
          </a>
        </div>
      </div>
    </nav>
  );
};

const NavBar = () => {
  const isMobile = useMediaQuery({ maxWidth: 1249 });

  return isMobile ? <MobileNavBar /> : <ResponsiveNavBar />;
};

export default NavBar;
