import React from 'react';
import './BlogDetails.css';

const BlogDetails = ({ data }) => {
  // Ensure data exists and has the expected structure
  const {title, content, date } = data || {};

  return (
    <div className="blogDetailsContainer">
      <div className="blogHeader">
        {/* Display the blog image */}
        <h1 className="blogTitle">{title || 'Default Title'}</h1>
        <p className="blogDate">{date || 'Default Date'}</p>
      </div>
      <div className="blogContent">
        
        {/* Iterate through the content array and display each point */}
        <div className="blogBody">
          {content && content.length > 0 ? (
            content.map((point, index) => (
              <p key={index} className="blogPoint">{point}</p>
            ))
          ) : (
            <p>No content available</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default BlogDetails;
