import React from 'react';
import Slider from 'react-slick';
import './ProjectImageScroll2.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';


const ProjectImageScroll2 = ({ data = [], title, detail }) => {
  if (!data || data.length === 0) return null;

  const CustomPrevArrow = ({ onClick }) => (
    <div className="custom-arrow prev" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );
  
  const CustomNextArrow = ({ onClick }) => (
    <div className="custom-arrow next" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };
  
  

  return (
    <div className="ProjectImage-Container">
      <h1 className="ProjectImage-title1">{title}</h1>
      <h2 className="ProjectImage-title2">{detail}</h2>

      <Slider {...settings} className="ProjectImage-carousel">
        {data.map((image, index) => (
          <div className="carousel-slide" key={index}>
            <img
              src={image.url.replace(/\.(jpg|jpeg|png)$/i, '.webp')}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = image.url;
              }}
              alt={`Project Image ${index + 1}`}
              className="carousel-image"
              loading="lazy"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProjectImageScroll2;
