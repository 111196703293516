import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Tooltip } from 'react-leaflet';
import './ProjectLocation.css'; // Make sure you include this
import L from 'leaflet';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Button from '../Button/Button';
import LoadingWave from '../Loading/LoadingWave';

const customIcon = new L.Icon({
  iconUrl: '/Projects/sitedata/map.svg',
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  popupAnchor: [0, -40],
  className: 'custom-svg-marker',
});



const ProjectLocation = () => {
  const [projectLocations, setProjectLocations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get('https://avenue-website-backend.vercel.app/projects');
        const projects = response.data.map((project) => {
          const location = project.location?.[0]; // Access the first element in the location array
          return {
            id: project._id,
            name: project.name,
            location: {
              address: location?.address,
              addressFull: location?.addressFull,
              lat: location?.latitude,
              lng: location?.longitude,
              url: location?.url,
              sentence: location?.sentence,
            },
            photo: project.photos?.[0]?.url, // Assuming photos[0] exists and has a 'url' field
          };
        });
        setProjectLocations(projects);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching project data:', error);
        setLoading(false);
      }
    };
    fetchProjects();
  }, []);

  const defaultCenter = [18.566023, 73.772202];

  if (loading) {
    return (
      <div className="loading-container">
        <LoadingWave /> 
      </div>
    );
  }

  return (
    <MapContainer
      center={defaultCenter}
      zoom={15}
      className="map-container"
      scrollWheelZoom={false} // Disables zooming with mouse wheel
      dragging={true} // Allows dragging with mouse
      doubleClickZoom={false} // Prevents zooming on double click
      zoomControl={true} // Enables zoom with + and - buttons
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {projectLocations.map((project) => {
        // Check if latitude and longitude are valid
        if (project.location.lat && project.location.lng) {
          return (
            <Marker
              key={project.id}
              position={[project.location.lat, project.location.lng]}
              icon={customIcon}
            >
              <Tooltip permanent direction="top" offset={[0, -20]} className="project-name-tooltip">
                {project.name}
              </Tooltip>
              <Popup>
                <div className='popup-container'>
                  <h4 className='ProjectMapName'>{project.name}</h4>
                  {/* <p>{project.location.sentence}</p> */}
                  <img
                    src={project.photo}
                    alt={project.name}
                    style={{
                      width: '300px',
                      height: '200px',
                      margin: '0 auto',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    
                  />
                  {/* <p className='ProjectMapAddress'>{project.location.address}</p> */}
                  <p className='ProjectMapAddressFull'>{project.location.addressFull}</p>
                  {/* <Link to={`/project/${project.id}`} className="project-link">
                    View Details
                  </Link> */}
                  <Button to={`/project/${project.id}`} children={"View Details"}/>
                  {/* <a href={project.location.url} target="_blank" rel="noopener noreferrer">
                    Open in Maps
                  </a> */}
                  <Button to={project.location.url} children={"project.location.url"}/>
                </div>
              </Popup>
            </Marker>
          );
        }
        return null; // Skip rendering marker if lat/lng is missing
      })}
    </MapContainer>
  );
};

export default ProjectLocation;
