import React from 'react';
import './Contact.css';
import { getStoredData } from "../../json/fetchData.js";
import AllIntroTemplate from '../../components/AllIntroTemplate/AllIntroTemplate';
import ContactUsForm from '../../components/ContactUsForm/ContactUsForm';
import ContactOfficeDetails from '../../components/ContactOfficeDetails/ContactOfficeDetails';

const data = getStoredData()?.["10"] ?? {};

const Contact = () => {
  return (
    <div>
      <AllIntroTemplate title={data.ContactIntroTitle} sentence={data.ContactIntroSentence} photo={data.ContactIntroPhoto}/>
      <ContactUsForm data={data.ContactContent}/>
      <ContactOfficeDetails data={data.ContactMap}/>

    </div>
  )
}

export default Contact;
