import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import './AllFaqs.css'; // Updated CSS file name for better clarity

const AllFaqs = ({ data }) => {
  // State to manage visibility of answers
  const [openIndex, setOpenIndex] = useState(null);

  // Function to toggle visibility of answer
  const toggleAnswer = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div>
      <h3 className='faqs-heading'>FAQ's</h3>
      <div className="faqs-wrapper">
        {/* Map through data to render questions and answers */}
        {data.map((faq, index) => (
          <div key={index} className="faq-block">
            <div
              className={`faq-question ${openIndex === index ? 'active' : ''}`}
              onClick={() => toggleAnswer(index)}
            >
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="faq-question-icon"
              />
              <h1 className='faq-questionTitle'>{faq.questions}</h1>
              {/* Render down or up icon based on openIndex */}
              <FontAwesomeIcon
                icon={openIndex === index ? faChevronUp : faChevronDown}
                className={`faq-icon-toggle ${openIndex === index ? 'active' : ''}`}
              />
            </div>
            {openIndex === index && (
              <div className="faq-answer">{faq.answer + " ?"}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllFaqs;