import React from 'react';
import { getStoredData } from "../../json/fetchData.js";
import DynamicForm from '../../components/DynamicForm/DynamicForm';

const AddTestimonialsPage = () => {
  const fields = getStoredData()?.["19"]?.AdminTestimonial ?? {}
  const apiUrl = 'https://avenue-website-backend.vercel.app/testimonial'; // API endpoint for creating product
  const successRedirect = '/AdminTestimonial'; // Redirect after successful product creation

  return (
    <div>
      <DynamicForm fields={fields} apiUrl={apiUrl} successRedirect={successRedirect} />
    </div>
  );
};

export default AddTestimonialsPage