import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios for API calls
import './BlogPage.css';
import AllIntroTemplate from '../../components/AllIntroTemplate/AllIntroTemplate';
import BlogDetails from '../../components/BlogDetails/BlogDetails';
import HomeBlog from '../../components/HomeBlog/HomeBlog';
import { useParams } from 'react-router-dom'; // Import useParams to get the ID from the route

const BlogPage = () => {
  const { id } = useParams(); // Get the blog ID from the URL parameters
  const [blogData, setBlogData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the blog data from the API
    const fetchBlogData = async () => {
      try {
        const response = await axios.get(`https://avenue-website-backend.vercel.app/blog/${id}`);
        setBlogData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blog data:', error);
        setError('Failed to fetch blog data');
        setLoading(false);
      }
    };
    fetchBlogData();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      {blogData && (
        <>
          <AllIntroTemplate title={blogData.title} sentence={blogData.details} photo={blogData.photo} />
          <BlogDetails data={blogData} />
        </>
      )}
      <HomeBlog />
    </div>
  );
};

export default BlogPage;
