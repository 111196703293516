import React,{useState, useEffect} from 'react';
import './Blog.css';
import { getStoredData } from "../../json/fetchData.js";
import AllIntroTemplate from '../../components/AllIntroTemplate/AllIntroTemplate';
import SliderBlog from '../../components/SliderBlog/SliderBlog';
import LoadingIcon from '../../components/Loading/Loading';


const data = getStoredData()?.["9"] ?? {};


const Blog = () => {

  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch blog data from the API
    fetch('https://avenue-website-backend.vercel.app/blog')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch blog data');
        }
        return response.json();
      })
      .then((data) => {
        setBlogPosts(data); // Set the fetched data to state
        setLoading(false); // Stop loading once data is fetched
      })
      .catch((err) => {
        setError(err.message); // Handle any errors
        setLoading(false);
      });
  }, []); // Empty dependency array ensures this runs once when the component mounts



  if (loading) {
    return (
      <div className="loading-container">
        <LoadingIcon /> {/* Use LoadingIcon while loading */}
      </div>
    );
  }
 

  if (error) {
    return <p>Error: {error}</p>; // Show error message if there is an error
  }
  return (
    <div>
      <AllIntroTemplate title={data.BlogIntroTitle} sentence={data.BlogIntroSentence} photo={data.BlogIntroPhoto}/>
      <SliderBlog data={blogPosts}/>
    </div>
  )
}

export default Blog;
