import React, { useState, useEffect } from 'react';
import './ProjectIntroVideo2.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useInView } from 'react-intersection-observer';



const ProjectIntroVideo2 = ({ data, logo }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [ref, inView] = useInView({ triggerOnce: true });

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? data.length - 1 : prevIndex - 1));
  };

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getImageSource = (photo) => {
    const path = photo.startsWith('http') ? photo : `${process.env.PUBLIC_URL}${photo}`;
    return encodeURI(path); // Encode to handle spaces and special characters
  };

  return (
    <div ref={ref} className="project-intro-video" style={{
        backgroundImage: inView ? `url(${getImageSource(data[currentIndex].photo)})` : 'none',
        backgroundPositionY: `${scrollPosition * 0}px`
      }}>
      {/* Optional logo */}
      {/* {logo && (
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo-image" />
        </div>
      )} */}

      <div className="quote-container">
        <p className="image-quote">{data[currentIndex].quote}</p>
      </div>

      <div className="number-navigation">
        {data.map((_, index) => (
          <div
            key={index}
            className={`number ${index === currentIndex ? 'active' : ''}`}
            onClick={() => setCurrentIndex(index)}
          >
            {index + 1}
          </div>
        ))}
      </div>

      <div className="navigation-controls">
        <FontAwesomeIcon icon={faChevronLeft} className="nav-icon left" onClick={handlePrev} />
        <FontAwesomeIcon icon={faChevronRight} className="nav-icon right" onClick={handleNext} />
      </div>
    </div>
  );
};

export default ProjectIntroVideo2;
