import React, { useState, useEffect } from 'react';
import './ProjectAmenities.css';

const ProjectAmenities = ({ name, amenities }) => {
  const [currentIndex, setCurrentIndex] = useState(0); // Track current set of cards
  const [cardsPerView, setCardsPerView] = useState(3); // Default number of cards to show at a time

  // Adjust the cardsPerView based on screen size
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 786) {
        setCardsPerView(1); // Show 1 card on smaller screens
      } else {
        setCardsPerView(3); // Show 3 cards on larger screens
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Check the initial size on mount

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Move to the previous set of cards
  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? amenities.length - cardsPerView : prevIndex - cardsPerView
    );
  };

  // Move to the next set of cards
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex >= amenities.length - cardsPerView ? 0 : prevIndex + cardsPerView
    );
  };

  // Auto-slide to the next set of cards every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 10000); // 10 seconds in milliseconds

    return () => clearInterval(interval); // Clear the interval on unmount to avoid memory leaks
  }, [currentIndex, amenities.length, cardsPerView]); // Run the effect when currentIndex or amenities length changes

  // Return null if amenities data is missing or empty
  if (!amenities || amenities.length === 0) {
    return null;
  }

  return (
    <div className="amenities-scroll-container">
      <h2 className="amenities-title">Key Amenities at</h2>
      <h3 className="amenities-ProjectName">{name}</h3>
      
      <div className="amenities-carousel">
        {/* Previous button */}
        <button className="prev-button" onClick={handlePrev}>
          &lt;
        </button>

        {/* Card display */}
        <div className="amenities-card-wrapper">
          {amenities.slice(currentIndex, currentIndex + cardsPerView).map((amenity, index) => (
            <div key={index} className="amenity-card">
              <div className="amenity-card-inner">
                {/* Front of the card */}
                <div
                  className="amenity-card-front"
                  style={{ backgroundImage: `url(${encodeURI(amenity.photo)})` }}
                >
                  <div className="amenity-text-overlay">
                    <h3 className="amenity-name">{amenity.name}</h3>
                  </div>
                </div>

                {/* Back of the card */}
                <div className="amenity-card-back">
                  <h4 className="amenity-quote">"{amenity.quote}"</h4>
                  <p className="amenity-sentence">{amenity.sentence}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Next button */}
        <button className="next-button" onClick={handleNext}>
          &gt;
        </button>
      </div>

      {/* Dots navigation */}
      <div className="dots-navigation">
        {Array.from({ length: Math.ceil(amenities.length / cardsPerView) }).map((_, index) => (
          <span
            key={index}
            className={`dot ${index === Math.floor(currentIndex / cardsPerView) ? 'active' : ''}`}
            onClick={() => setCurrentIndex(index * cardsPerView)}
          />
        ))}
      </div>
    </div>
  );
};

export default ProjectAmenities;
