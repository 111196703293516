import React, { useEffect, useState } from 'react';
import './AllIntroTemplate.css';

const AllIntroTemplate = ({ title, sentence, photo }) => {
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Encode the photo URL to handle spaces and special characters
  const encodedPhoto = encodeURI(photo);

  return (
    <div
      className="AllIntroTemplate"
      style={{
        backgroundImage: `url(${encodedPhoto})`,
        backgroundPositionY: `${scrollY * 0}px`, // Parallax scrolling effect
      }}
    >
      <div className="ContentContainer">
        <div className="TitleContainer">
          <h1>{title}</h1>
        </div>
        <div className="SentenceContainer">
          <p>{sentence}</p>
        </div>
      </div>
    </div>
  );
};

export default AllIntroTemplate;
