import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './MobileNavBar.css';
import { FaPlus } from 'react-icons/fa';
import { getStoredData } from "../../json/fetchData.js";

const MobileNavBar = () => {
  const menuData = getStoredData();
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null); 

  const toggleMenu = () => {
    setMenuOpen(prev => !prev);
  };

  const toggleSubMenu = (index) => {
    setActiveIndex(activeIndex === index ? null : index); 
  };

  return (
    <nav className="MobileNavBarContainer">
      <div className="MobileNavBarHeader">
        <Link to="/">
          <img src={menuData[0].logo} alt="Logo" className="MobileLogoImage" />
        </Link>
        <div className={`MobileMenuIcon`} onClick={toggleMenu}>
          <div className={`MenuIconTransition ${menuOpen ? 'open' : ''}`}>
            <div className="bar bar1"></div>
            <div className="bar bar2"></div>
            <div className="bar bar3"></div>
          </div>
        </div>
      </div>
      <ul className={`MobileNavBarList ${menuOpen ? 'show' : ''}`}>
        {menuData[0].menu.map((item, index) => (
          <li key={index} className="MobileNavBarItem">
            {item.submenu ? (
              <>
                <div className="MobileNavBarLinkWithIcon" onClick={() => toggleSubMenu(index)}>
                  {item.name}
                  <FaPlus className={`MobileMenuIcon-ChevronIcon ${activeIndex === index ? 'MobileMenuIcon-rotate-up' : 'MobileMenuIcon-rotate-down'}`} />
                </div>
                <ul className={`MobileSubMenuList ${activeIndex === index ? 'show' : ''}`}>
                  {item.submenu.map((subItem, subIndex) => (
                    <li key={subIndex} className="MobileSubMenuItem">
                      <Link to={subItem.link} className="MobileSubMenuLink" onClick={() => setMenuOpen(false)}>
                        {subItem.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <Link to={item.link} className="MobileNavBarLink" onClick={() => setMenuOpen(false)}>
                {item.name}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default MobileNavBar;
