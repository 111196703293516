import React from 'react';
import './Corporate.css';
import AllIntroTemplate from '../../components/AllIntroTemplate/AllIntroTemplate';
import AllAboutUs from '../../components/AllAboutUs/AllAboutUs';
import AllValues from '../../components/AllValues/AllValues';
import AllKeyMembers from '../../components/AllKeyMembers/AllKeyMembers';
import CompanyProfile from '../../components/CompanyProfile/CompanyProfile';
import PhotoSlider from '../../components/CompanyJourney/CompanyTimelineGrid';
import { getStoredData } from "../../json/fetchData.js";

const Corporate = () => {
  const data = getStoredData()?.["3"] ?? {};
  return (
    <div>
      <AllIntroTemplate  title={data.CorporateintroTitle} sentence={data.CorporateintroSentence} photo={data.CorporateintroPhoto}/>
      <AllAboutUs title={data.AboutUsTitle} sentence={data.AboutUsSentence} card={data.AboutUsCard}/>
      <AllValues />
      <AllKeyMembers title={data.keyMembersTitle} card={data.keyMembersCard}/>
      <PhotoSlider cards={data.Timeline}/>
      <CompanyProfile data={data.CorporatePdf}/>
    </div>
  )
}

export default Corporate;
