import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Admin.css';
import { getStoredData } from "../../json/fetchData.js";

const Admin = () => {
  const navigate = useNavigate();
  const data = getStoredData()?.["19"] ?? {};

  // Check if the user is authenticated
  useEffect(() => {
    const authenticated = localStorage.getItem('authenticated');
    if (!authenticated) {
      navigate(data.AdminAuthLink ?? '/login'); // Use AdminAuthLink for login redirection
    }
  }, [navigate, data.AdminAuthLink]);

  const handleRedirect = (path) => {
    navigate(path);
  };

  return (
    <div className="admin-panel">
      <div className="card-container">
        {data.AdminPanel?.map((panel) => (
          <div
            key={panel.PanelLink}
            className="card-box"
            onClick={() => handleRedirect(panel.PanelLink)}
          >
            <h3>{panel.PanelTitle}</h3>
            <p>{panel.PanelDetails}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Admin;
