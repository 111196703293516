import React from 'react';
import './NRIService.css';
import { getStoredData } from "../../json/fetchData.js";
import AllIntroTemplate from '../../components/AllIntroTemplate/AllIntroTemplate';
import AllAboutUs from '../../components/AllAboutUs/AllAboutUs';
import ContactUsForm from '../../components/ContactUsForm/ContactUsForm';
import AllFaqs from '../../components/AllFaqs/AllFaqs';

const data = getStoredData()?.["7"] ?? {};

const NRIService = () => {
  return (
    <div>
      <AllIntroTemplate title={data.NRIIntroTitle} sentence={data.NRIIntroSentence} photo={data.NRIIntroPhoto}/>
      <AllAboutUs title={data.AboutUsTitle} sentence={data.AboutUsSentence} card={data.AboutUsCard}/>
      <ContactUsForm data={data.nriContent}/>
      <AllFaqs data={data.faqs}/>
    </div>
  )
}

export default NRIService;
