import React, { useState } from 'react';
import './AllProjectsList.css';
import { Link } from 'react-router-dom';

const AllProjectsList = ({ projects }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Sort projects by priority before filtering
  const sortedProjects = projects.sort((a, b) => a.priority - b.priority);

  const filteredProjects = sortedProjects.filter((project) =>
    project.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getImageSource = (photo) => {
    // Use PUBLIC_URL for local images in public folder
    const path = photo.startsWith('http') ? photo : `${process.env.PUBLIC_URL}${photo}`;
    return encodeURI(path); // Encode to handle spaces and special characters
  };

  return (
    <div className="all-projects-container">
      {/* Search Bar */}
      <div className="search-bar">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search projects..."
        />
      </div>

      {/* Projects Grid View */}
      <div className="projects-grid">
        {filteredProjects.length > 0 ? (
          filteredProjects.map((project) => (
            <div key={project._id} className="project-card">
              <Link to={`/project/${project._id}`} className="project-link">
                <div className="project-background" style={{ backgroundImage: `url(${getImageSource(project.photos[0]?.url)})` }}>
                  <div className="dark-overlay"></div> 
                  
                  <div className="project-info-overlay">
                    <p className="project-info-address">
                      {project.location?.[0]?.address || 'No address available'}
                    </p>
                    <h3 className="project-info-name">{project.name}</h3>
                    <hr />
                    <p className="extra-info">{project.reraNumber || 'RERA not available'}</p>
                  </div>

                  {/* Always show project type and status */}
                  <div className="project-status-container">
                    <p className="project-status">{project.status || 'Unknown Status'}</p>
                    {/* <p className="project-type">{project.type || 'Type not available'}</p> */}
                  </div>
                </div>
              </Link>
            </div>
          ))
        ) : (
          <div className="no-results">No projects found.</div>
        )}
      </div>
    </div>
  );
};

export default AllProjectsList;
