import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube, FaLinkedinIn, FaPlus, FaArrowUp } from 'react-icons/fa'; // Import FaArrowUp icon
// import data from '../../json/data.json';
import Button from '../../components/Button/Button';
import { getStoredData } from "../../json/fetchData.js";

const Footer = () => {
  const data = getStoredData() ?? {};
  const footerData = getStoredData()?.["1"] ?? {};
  const [activeIndex, setActiveIndex] = useState(null);
  const [email, setEmail] = useState(''); // State to store the email input
  const [submissionStatus, setSubmissionStatus] = useState(''); // State to display submission status
  const [showScrollButton, setShowScrollButton] = useState(false); // State to show/hide scroll button

  // Function to toggle submenu
  const toggleSubMenu = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Function to check scroll position and show button when footer is in view
  const checkScrollPosition = () => {
    const footer = document.querySelector('.Footer');
    if (footer) {
      const footerTop = footer.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      if (footerTop <= windowHeight) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    }
  };

  // UseEffect to add scroll listener
  useEffect(() => {
    window.addEventListener('scroll', checkScrollPosition);
    return () => window.removeEventListener('scroll', checkScrollPosition);
  }, []);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://avenue-website-backend.vercel.app/form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }), // Send only email
      });

      if (!response.ok) {
        throw new Error('Failed to submit email');
      }

      const result = await response.json();
      setSubmissionStatus('Success! Email submitted.');
      setEmail(''); // Reset email field after successful submission
    } catch (error) {
      console.error(error);
      setSubmissionStatus('Error submitting email.');
    }
  };

  return (
    <footer className="Footer">
      <div className="Footer-Above">
        <div className='Footer-AboveContainer'>

          <div className="Footer-Explore">
            <h1 className='Footer-ExploreHeader'>{footerData.footerExploreHeader}</h1>
            <ul className="Footer-NavList">
              {data[0].menu.map((item, index) => (
                <li key={index} className="Footer-NavItem">
                  {item.submenu ? (
                    <>
                      <div className="Footer-NavLinkWithIcon" onClick={() => toggleSubMenu(index)}>
                        {item.link !== "na" ? (
                          <Link to={item.link} className="Footer-NavData">
                            {item.name}
                          </Link>
                        ) : (
                          <span className="Footer-NavData">{item.name}</span>
                        )}
                        <FaPlus className={`Footer-Icon ${activeIndex === index ? 'Footer-Icon-rotate-up' : 'Footer-Icon-rotate-down'}`} />
                      </div>
                      <ul className={`Footer-SubMenuList ${activeIndex === index ? 'show' : ''}`}>
                        {item.submenu.map((subItem, subIndex) => (
                          <li key={subIndex} className="Footer-SubMenuItem">
                            {subItem.link !== "na" ? (
                              <Link to={subItem.link} className="Footer-SubMenuLink">
                                {subItem.name}
                              </Link>
                            ) : (
                              <span className="Footer-SubMenuLink">{subItem.name}</span>
                            )}
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    item.link !== "na" ? (
                      <Link to={item.link} className="Footer-NavData">
                        {item.name}
                      </Link>
                    ) : (
                      <span className="Footer-NavData">{item.name}</span>
                    )
                  )}
                </li>
              ))}
            </ul>
          </div>

          <div className="Footer-Contact">
            <h1 className="Footer-HeaderContact">{footerData.footerContactTitle}</h1>
            <div className="Footer-ContactInfo">
              <p className="Footer-ContactDetails">
              <span className="Footer-ContactTitle">Contact Us: </span>
                <a href={`tel:${footerData.footercontactNumber}`} className="Footer-ContactEmail">
                  {footerData.footercontactNumber}
                </a>
              </p>
              <p className="Footer-ContactDetails">
  <span className="Footer-ContactTitle">Email Us: </span>
  <a href={`mailto:${footerData.footercontactEmail}`} className="Footer-ContactEmail">
    {footerData.footercontactEmail}
  </a>
</p>
<p className="Footer-ContactDetails">
  <span className="Footer-ContactTitle">Office Address: </span>
  <a href={footerData.footercontactAddressLink} className="Footer-ContactEmail">
    {footerData.footercontactAddress}
  </a>
</p>

            </div>

            <div className="Footer-ContactLogos">
              <a href="https://www.facebook.com/avenuehousing/" target="_blank" rel="noopener noreferrer" className="Footer-SocialIcon">
                <FaFacebookF />
              </a>
              <a href="https://www.instagram.com/avenuehousing/" target="_blank" rel="noopener noreferrer" className="Footer-SocialIcon">
                <FaInstagram />
              </a>
              <a href="https://www.youtube.com/@AvenueGroupdigital/featured" target="_blank" rel="noopener noreferrer" className="Footer-SocialIcon">
                <FaYoutube />
              </a>
              <a href="https://www.linkedin.com/company/avenue-group-pune" target="_blank" rel="noopener noreferrer" className="Footer-SocialIcon">
                <FaLinkedinIn />
              </a>
            </div>

            <Link to={footerData.footerContactButtonLink}>
              <Button to={footerData.footerContactButtonLink}>{footerData.footerContactButton}</Button>
            </Link>
          </div>

          <div className="Footer-Form">
            <h1 className="Footer-HeaderForm">{footerData.footerFormTitle}</h1>
            <form className="Footer-FormBox" onSubmit={handleSubmit}>
              <div className="Footer-FormGroup">
                <label htmlFor="email" className="Footer-FormLabel"></label>
                <input
                  type="email"
                  placeholder='EMAIL ADDRESS'
                  id="email"
                  name="email"
                  className="Footer-FormInput"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} 
                  required
                />
              </div>
              {/* <button type="submit" className="Footer-SubmitButton">
    {footerData.footerFormButton}
  </button> */}
  <Button type="submit" >
  Subscribe
</Button>

            </form>
            {submissionStatus && <p className="Footer-SubmissionStatus">{submissionStatus}</p>}
          </div>

        </div>
      </div>

      <div className="Footer-Below">
        <div className="Footer-Policy">
          <p className="Footer-PolicyPrivacy">
            <a href={footerData.footerPolicyPrivacyLink} className="Footer-PolicyLink">
              {footerData.footerPolicyPrivacy}
            </a>
          </p>
          <p className="Footer-PolicyDisclaimer">
            <a href={footerData.footerPolicyDisclaimerLink} className="Footer-PolicyLink">
              {footerData.footerPolicyDisclaimer}
            </a>
          </p>
          <p className="Footer-PolicyTermsOfUse">
            <a href={footerData.footerPolicyTermsOfUseLink} className="Footer-PolicyLink">
              {footerData.footerPolicyTermsOfUse}
            </a>
          </p>
        </div>
        <div className="Footer-BelowLine">
          <div className="Footer-Rights">
            <p className="Footer-AllRights">
              {footerData.footerAllRights}
            </p>
            <p className="Footer-PolicyPrivacy">
              {footerData.footerDesignCredit}{' '}
              <Link to={footerData.footerDesignLink} className='Footer-PolicyPrivacyLink'>{footerData.footerDesignLinkHeader}</Link>
            </p>
          </div>
          <div className="Footer-Logo">
            <img src={footerData.footerLogoImage} alt="OGCS Logo" className="Footer-LogoImage" />
          </div>
        </div>
      </div>
      {showScrollButton && (
        <button className="ScrollToTopButton" onClick={scrollToTop}>
          <FaArrowUp />
        </button>
      )}
    </footer>
  );
};

export default Footer;
