import React, { useState, useCallback, useEffect } from 'react';
import './ProjectTimeline.css'; // Import CSS for styling
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const ProjectTimeline = ({ cards }) => {
  // Sort cards from newest (highest Year) to oldest
  const sortedCards = [...cards].sort((a, b) => b.Year - a.Year);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0); // 1 for right, -1 for left
  const [scrollY, setScrollY] = useState(0); // Track scroll position for parallax

  // Navigate to the next card
  const goToNextCard = useCallback(() => {
    setDirection(1);
    setCurrentIndex((prevIndex) => (prevIndex === sortedCards.length - 1 ? 0 : prevIndex + 1));
  }, [sortedCards.length]);

  // Define animations
  const animationControls = useAnimation();
  const [ref, inView] = useInView();

  // Animate when the element comes into view
  useEffect(() => {
    if (inView) {
      animationControls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 1 },
      });
    }
  }, [animationControls, inView]);

  // Automatically change to the next card after 60 seconds
  useEffect(() => {
    const timer = setTimeout(goToNextCard, 60000); 
    return () => clearTimeout(timer);
  }, [currentIndex, goToNextCard]);

  const handleDotClick = (index) => {
    if (index !== currentIndex) {
      setDirection(index > currentIndex ? 1 : -1); // Determine the direction
      setCurrentIndex(index);
    }
  };

  // Parallax effect on scroll
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY); // Track scroll position
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className='ProjectTimeline-container'>
      {/* Stationary Dots */}
      <div className="ProjectTimeline-dots">
        {sortedCards.map((card, index) => (
          <div key={index} className="ProjectTimeline-YearContainer">
            {index === currentIndex && (
              <div className="ProjectTimeline-dot-year">{card.Year}</div>
            )}
            <div
              className={`ProjectTimeline-dot ${index === currentIndex ? 'active' : ''}`}
              onClick={() => handleDotClick(index)}
            >
              {index === currentIndex && (
                <div className="ProjectTimeline-dot-year">{card.Year}</div>
              )}
              <div className="ProjectTimeline-dot-inner" />
            </div>
          </div>
        ))}
      </div>

      {/* Animated Content and Background */}
      <motion.div
        className="ProjectTimeline-slider"
        ref={ref}
        initial={{ opacity: 0, y: 60 }}
        animate={animationControls}
      >
        <motion.div
          className="ProjectTimeline-background"
          style={{ 
            backgroundImage: `url(${encodeURI(sortedCards[currentIndex].image)})`, // Handle spaces in image paths
            backgroundPositionY: `${scrollY * 0}px` // Parallax effect on scroll - Adjust the value to change intensity
          }}
          initial={{ x: direction === 1 ? 300 : -300, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            x: { 
              type: 'spring', 
              stiffness: 50, 
              damping: 20, 
              duration: 1.5 
            },
            opacity: { duration: 0.8, ease: [0.42, 0, 0.58, 1] },
          }}
          key={currentIndex}
        >
          <div className="ProjectTimeline-content">
            <div className="ProjectTimeline-text">
              <h2 className="ProjectTimeline-heading">{sortedCards[currentIndex].heading}</h2>
              <p className="ProjectTimeline-description">{sortedCards[currentIndex].description}</p>
            </div>
            <div className="ProjectTimeline-year">
              <h1 className="ProjectTimeline-yearText">{sortedCards[currentIndex].Year}</h1>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default ProjectTimeline;
