import React, { useState, useEffect } from "react";
import "./PrivacyPolicy.css"; // Import CSS file
import { getStoredData } from "../../json/fetchData.js";



const PrivacyPolicy = () => {

    const data = getStoredData()?.["20"] ?? {};
  if (!data) return <p>Loading Privacy Policy...</p>;

  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p><strong>Last Updated:</strong> {data.lastUpdated}</p>

      <p>
        {data.companyName} ("we," "our," or "us") respects your privacy and is
        committed to protecting your personal information. This Privacy Policy
        explains how we collect, use, disclose, and safeguard your data when
        you visit our website <strong>{data.website}</strong> (the "Site").
      </p>

      <h2>1. Information We Collect</h2>

      <h3>(a) Personal Information You Provide</h3>
      <ul>
        {data.dataCollection.personalInfo.map((info, index) => (
          <li key={index}>{info}</li>
        ))}
      </ul>

      <h3>(b) Automatically Collected Information</h3>
      <ul>
        {data.dataCollection.automaticInfo.map((info, index) => (
          <li key={index}>{info}</li>
        ))}
      </ul>

      <h3>(c) Information from Facebook & Third-Party Services</h3>
      <ul>
        {data.dataCollection.thirdPartyInfo.map((info, index) => (
          <li key={index}>{info}</li>
        ))}
      </ul>

      <h2>2. How We Use Your Information</h2>
      <ul>
        {data.usagePurposes.map((purpose, index) => (
          <li key={index}>{purpose}</li>
        ))}
      </ul>

      <h2>3. Sharing Your Information</h2>
      <ul>
        <li><strong>{data.sharingInfo.authorizedProviders}</strong></li>
        <li><strong>{data.sharingInfo.facebookPartners}</strong></li>
        <li><strong>{data.sharingInfo.legalAuthorities}</strong></li>
      </ul>

      <h2>4. Facebook Data & Opt-Out</h2>
      <p>{data.facebookOptOut.description}</p>
      <ul>
        {data.facebookOptOut.optOutLinks.map((link, index) => (
          <li key={index}>
            <a href={link.url} target="_blank" rel="noopener noreferrer">
              {link.text}
            </a>
          </li>
        ))}
      </ul>

      <h2>5. Data Protection & Security</h2>
      <ul>
        {data.securityMeasures.map((measure, index) => (
          <li key={index}>{measure}</li>
        ))}
      </ul>

      <h2>6. Cookies & Tracking Technologies</h2>
      <ul>
        {data.cookies.types.map((cookie, index) => (
          <li key={index}>{cookie}</li>
        ))}
      </ul>
      <p className="cookie-notice">{data.cookies.notice}</p>

      <h2>7. Updates to This Privacy Policy</h2>
      <p>{data.updatesNotice}</p>

      <h2>8. Contact Us</h2>
      <div className="contact-info">
        <ul>
          <li>📩 Email: <a href={`mailto:${data.contact.email}`}>{data.contact.email}</a></li>
          <li>🏢 Office Address: {data.contact.address}</li>
          <li>📞 Phone: {data.contact.phone}</li>
        </ul>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
