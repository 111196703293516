import React from 'react';
import DynamicEditForm from '../../components/DynamicEditForm/DynamicEditForm.js'; // Import your form component
import { getStoredData } from "../../json/fetchData.js";

const EditServicesPage = () => {
  const fields = getStoredData()?.["19"]?.AdminServices ?? {};
  const apiUrl = 'https://avenue-website-backend.vercel.app/services'; // API URL for fetching/editing product
  const successRedirect = '/AdminServices'; // Where to redirect after a successful update

  return (
    <div>
      <DynamicEditForm
        fields={fields}
        apiUrl={apiUrl}
        successRedirect={successRedirect}
      />
    </div>
  );
};
export default EditServicesPage