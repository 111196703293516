import React, { useState, useEffect } from 'react';
import './PopupAd.css'; // Your CSS file

const PopupAd = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [randomImage, setRandomImage] = useState('');

  // Dynamically import all images from the 'ads' folder
  const importAllImages = (requireContext) => {
    return requireContext.keys().map(requireContext);
  };

  // Replace with a valid path if needed
  const images = importAllImages(require.context('../../../public/ads', false, /\.(jpeg|jpg|png|gif)$/));

  useEffect(() => {
    // Check if the popup has been shown in the current session
    const hasShownPopup = sessionStorage.getItem('hasShownPopup');

    if (!hasShownPopup && images.length > 0) {
      // Pick a random image from the imported images
      const randomIndex = Math.floor(Math.random() * images.length);
      setRandomImage(images[randomIndex]);

      // Set a timer to show the popup after 3 seconds
      const timer = setTimeout(() => {
        setShowPopup(true);
        sessionStorage.setItem('hasShownPopup', 'true'); // Mark the popup as shown in this session
      }, 3000); // Delay before showing the popup

      // Set a timer to auto-close the popup after 30 seconds
      const autoCloseTimer = setTimeout(() => {
        setShowPopup(false);
      }, 30000); // Auto-close after 30 seconds

      return () => {
        clearTimeout(timer);
        clearTimeout(autoCloseTimer);
      };
    }
  }, [images]);

  if (!showPopup || !randomImage) return null; // Do not render if no popup or image

  return (
    <div className="popup-ad-overlay">
      <div className="popup-ad-content">
        <span className="popup-close-icon" onClick={() => setShowPopup(false)}>
          &times;
        </span>
        <img
          src={randomImage.default || randomImage}
          alt="Ad Poster"
          className="popup-ad-image"
        />
      </div>
    </div>
  );
};

export default PopupAd;
