import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import './ServicesTitle.css';

const ServicesTitle = ({ title, header, sentence, number }) => {
  // Split the sentence into individual sentences after each period (full stop).
  const sentenceArray = sentence.split('.').filter((s) => s.trim() !== '');

  return (
    <div className="services-title-container">
      {/* Title, Header, and Sentence Section */}
      <div className="services-main-content">
        <h1 className="services-title">{title}</h1>
        <h2 className="services-header">{header}</h2>
        <div className="services-sentence">
          {sentenceArray.map((sent, index) => (
            <p key={index}>{sent.trim()}.</p>
          ))}
        </div>
      </div>

      {/* Phone Details Section */}
      <div className="services-contact">
        <div className="phone-details">
          <FontAwesomeIcon icon={faPhone} className="phone-icon" />
          {/* Clicking on the phone number will trigger dialing */}
          <a href={`tel:${number}`} className="phone-number">
            {number}
          </a>
        </div>
      </div>
    </div>
  );
}

export default ServicesTitle;
